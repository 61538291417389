import React, {
  useMemo,
  useState,
  useEffect
} from 'react';

import {
  FormGroup,
  Label,
  Input,
  Badge,

  UncontrolledAccordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody
} from 'reactstrap';

import {
  inputId,
  inputName,
} from '../../helper/form';

const CustomerOnboardingProcessAcceptComercialCondictionsProducts = props => {
  const {
    configuration: {
      formName
    }
  } = props;

  const [products, setProducts] = useState(props?.products || [])


  const onChangeProduct = (product) => {
    setProducts(prevState => {
      const _products = _.map(prevState, pstate => {
        if(pstate.id == product.id){
          return product
        } else {
          return pstate
        }
      })

      return _products;
    })
  }

  const listProducts = () => {
    return(
      <div className="row">
        <div className="col-12">
          <div className="text-start mb-3 mt-3">
            <h2 className="fs-4 fw-bold mb-1">Personaliza tu plan</h2>
            <p className="small">Selecciona las opciones que deseas incluir</p>
          </div>
          <UncontrolledAccordion stayOpen>
            { _.map(products, (product, index) => {
              return(
                <ComercialConditionProduct
                  product={ product }
                  configuration={{
                    ...props.configuration, formName: `${ formName }[products_attributes][${ index }]`
                  }}
                  callbacks={{
                    onChangeProduct: onChangeProduct
                  }}
                  key={ `productComponent-${ product.id }` }
                />
              )
            })}
          </UncontrolledAccordion>
        </div>
      </div>
    )
  }

  const productFreeBadge = product => {
    if(product.price == 0){
      return(
        <Badge color="success" className='ml-1'>
          Incluido gratis
        </Badge>
      )
    }
  }

  const productPriceDetail = () => {
    let total = 0

    const selectedProducts = _.filter(products, 'is_selected');

    return (
      <div className="card bg-white mb-2 card-material">
        <div className="card-header">
          <h2 className="fs-4 fw-bold mb-0">Detalle de tu Plan mensual</h2>
        </div>
        <div className="card-body">
          <table className='table'>
            <thead>
              <tr>
                <th>Producto</th>
                <th>Cantidad</th>
                <th>Precio</th>
                <th>Total</th>
              </tr>
            </thead>

            <tbody>
              { _.map(selectedProducts, product => {
                return(
                  <tr key={ `productPriceDetail-${ product.id }` }>
                    <td>
                      { product.title }
                      { productFreeBadge(product) }
                    </td>
                    <td>{ product.quantity }</td>
                    <td>{ _.round(product.price, 4) }</td>
                    <td>{ _.round(product.quantity * product.price, 4) }</td>
                  </tr>
                )
              })}

              <tr className="fw-bold table-light small">
                <td colSpan='2'></td>
                <td>Subtotal (UF)</td>
                <td>{ subTotalProductPrice() }</td>
              </tr>

              <tr className="fw-bold table-light small">
                <td colSpan='2'></td>
                <td>IVA 19% (UF)</td>
                <td>{ taxProducts() }</td>
              </tr>

              <tr className="fw-bold table-light">
                <td colSpan='2'></td>
                <td>Total (UF)</td>
                <td>{ totalProducts() }</td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>
    )
  }

  const subTotalProductPrice = () => {
    const selectedProducts = _.filter(products, 'is_selected');

    let total = 0;

    _.each(selectedProducts, product => {
      total += _.round(product.quantity * product.price, 4)
    });

    return _.round(total, 4)
  }

  const taxProducts = () => {
    return _.round(subTotalProductPrice() * 0.19, 4)
  }

  const totalProducts = () => {
    return _.round(subTotalProductPrice() + taxProducts(), 4)
  }

  return(
    <div className="row">
      <div className="col-12 mb-3">
        { productPriceDetail() }

        { listProducts() }
      </div>
    </div>
  )
}

export default CustomerOnboardingProcessAcceptComercialCondictionsProducts;

const ComercialConditionProduct = props => {
  const {
    product,
    configuration: {
      formName
    },
    callbacks: {
      onChangeProduct: callbackOnChangeProduct
    }
  } = props;

  const disabledIsSelectedCheckbox = useMemo(() => {
    return product.is_required
  }, [])

  const onChangeProduct = (event, key) => {
    let value = event?.target?.value;
    const type = event.target.type;

    if(type == 'checkbox'){
      value = event.target.checked;
    }

    if(!product.is_custom && key == 'quantity'){
      value = product.quantity
    }

    product[key] = value;

    callbackOnChangeProduct(product);
  }

  const quantityInput = () => {
    if(product.product_type == 'users'){
      return(
        <FormGroup>
          <div className="text-center">
            <p className='h1'>{ product.quantity } usuarios</p>
          </div>

          <Input
            id={ inputId(formName, 'quantity') }
            name={ inputName(formName, 'quantity') }
            type="range"
            min={ 3 }
            max={ 100 }
            step={ 1 }
            value={ product.quantity }
            disabled={ !product.is_custom }
            onChange={ event => onChangeProduct(event, 'quantity') }
          />
        </FormGroup>
      )
    } else if(product.product_type == 'gbs'){
      return(
        <FormGroup>
          <div className="text-center">
            <p className='h1'>{ product.quantity } Gb</p>
          </div>

          <Input
            id={ inputId(formName, 'quantity') }
            name={ inputName(formName, 'quantity') }
            type="range"
            min={ 10 }
            max={ 100 }
            step={ 1 }
            value={ product.quantity }
            disabled={ !product.is_custom }
            onChange={ event => onChangeProduct(event, 'quantity') }
          />
        </FormGroup>
      )
    } else if(product.product_type == 'fea'){
       return(
        <FormGroup>
          <div className="text-center">
            <p className='h1'>{ product.quantity } Firmas avanzadas</p>
          </div>

          <Input
            id={ inputId(formName, 'quantity') }
            name={ inputName(formName, 'quantity') }
            type="range"
            min={ 0 }
            max={ 100 }
            step={ 1 }
            value={ product.quantity }
            disabled={ !product.is_custom }
            onChange={ event => onChangeProduct(event, 'quantity') }
          />
        </FormGroup>
      )
    }
  }

  const comercialConditionFile = () => {
    if(product?.comercial_conditions_file?.attached){
      return(
        <div className="row">
          <div className="col-12">
            <a
              className=""
              href={ product?.comercial_conditions_file?.url }
              target="_blank"
            >
              Términos y condiciones
            </a>
          </div>
        </div>
      )
    }
  }

  const badgeProductErrors = () => {
    if(_.keys(product.errors).length > 0){
      return(
        <Badge color="danger">
          Contiene errores
        </Badge>
      )
    }
  }

  const errorList = () => {
    if(_.keys(product.errors).length > 0){
      return(
        <ul className='text-danger'>
          { _.map(product.errors, errors => {
            return _.map(errors, error =>{
              return(
                <li>{ error }</li>
              )
            })
          })}
        </ul>
      )
    }
  }

  return(
    <AccordionItem key={ `accordionProduct-${ product.id }`}>
      <AccordionHeader targetId={ String(product.id) }>
        <FormGroup check>
          <Input
            name={ inputName(formName, 'is_selected') }
            id={ inputId(formName, 'is_selected') }
            type="checkbox"
            disabled={ disabledIsSelectedCheckbox }
            onChange={ event => onChangeProduct(event, 'is_selected') }
            checked={ product.is_selected || false }
          />
          {' '}
        </FormGroup>

        <Label check htmlFor={ inputId(formName, 'is_selected') }>
          { product.title } { badgeProductErrors() }
        </Label>
      </AccordionHeader>
      <AccordionBody accordionId={ String(product.id) }>

        { errorList() }

        <div
          dangerouslySetInnerHTML={{__html: product.body }}
        />

        <Input
          name={ inputName(formName, 'id') }
          id={ inputId(formName, 'id') }
          defaultValue={ product.id }
          type='hidden'
        />

        { quantityInput() }

        { comercialConditionFile()}

      </AccordionBody>
    </AccordionItem>
  )
}
