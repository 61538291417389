import React, {useState, useMemo} from "react"
import {
  FormGroup,
  Label,
  Input
} from "reactstrap";

import {
  inputId,
  inputName,
  drawTooltipHelp,
} from '../helper/form';

import FieldError from '../helper/FieldError';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import I18n from 'i18n-js';
import _ from "lodash";

const CustomFieldForm = (props)=>{
  const {
    data: {
      dataTypes
    },
    configuration:{
      resourceTypes,
      formName,
      context
    }
  } = props
  const [customField, setCustomField] = useState(props?.customField || {})
  const listSelectOptions = customField.list_options.map(option => ({ label: option, value: option }));
  // Se agrega z-index para evitar que las opciones
  // colisionen con el resto del formulario
  const selectStyle = {
    menu: (provided) => ({
      ...provided,
      zIndex: 2,
    }),
  };

  const dataTypeOptions = useMemo(() => {
    return(
      _.map(dataTypes, dataType => {
        return (
          { label: I18n.t(`custom_fields.data_types.${dataType}`), value: dataType }
        )
      })
    )
  }, [dataTypes])

  const resourceTypeOptions = useMemo(() =>{
    return(
      _.map(resourceTypes, resourceType => {
        return (
          { label: resourceType?.label, value: resourceType?.value }
        )
      })
    )
  }, [resourceTypes])

  const dataTypeSelectedOption = useMemo(() => {
    let dataType = customField.data_type
    return { label: I18n.t(`custom_fields.data_types.${dataType}`), value: dataType }
  }, [customField.data_type])

  const resourceTypeSelectedOption = useMemo(() => {
    return _.find(resourceTypes, { value: customField.resource_type })
  }, [customField.resource_type])

  const onChangeCustomField = (event, key) => {
    const type = event?.target?.type;
    let value = event?.target?.value;
    let _customField = {}
    if(type == 'checkbox'){
      value = event.target.checked;
    }
    _customField[key] = value
    setCustomField(prevState => {
      return { ... prevState, ..._customField }
    })
  }

  const onChangeListOptions = (options) => {
    const _customField = {}

    _customField['list_options'] = _.map(options, option => {
      return(
        option.value
      )
    })
    setCustomField(prevState => {
      return { ... prevState, ..._customField }
    })
  }

  const resourceTypeInput = () => {
    if(_.includes(["laboral", "corporate"], context)){
      return(
        <FormGroup>
          <FieldError errors={ customField?.errors?.resource_type || [] }>
            <Label
              for={ inputId(formName, 'resource_type') }
            >
              { I18n.t(`activerecord.attributes.custom_field.resource_type`) }
            </Label>
            <Select
              id={ inputId(formName, 'resource_type') }
              name={ inputName(formName, 'resource_type') }
              invalid={ _.has(customField?.errors, 'resource_type') }
              options={ resourceTypeOptions }
              value={ resourceTypeSelectedOption }
              onChange={ (event) => onChangeCustomField({ target: { value: event?.value } }, "resource_type") }
              placeholder={ `-- Selecciona una opción --` }
              styles={ selectStyle }
            />
          </FieldError>
        </FormGroup>
      )
    }
  }

  const labelInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ customField?.errors?.label || [] }  >
          <Label
            className='form-label'
            for={ inputName(formName, 'label') }
          >
            { I18n.t(`activerecord.attributes.custom_field.label`) }
          </Label>
          <Input
            type='text'
            name={ inputName(formName, 'label') }
            id={ inputId(formName, 'label') }
            value={ customField.label }
            onChange={ (event) => onChangeCustomField({ target: { value: event?.value } }, 'label') }
            invalid={ _.has(customField?.errors, 'label') }
          />
        </FieldError>
      </FormGroup>
    )
  }

  const groupNameInput = () => {
    if(context == "abstract" || context == "karin_law"){
      return(
        <FormGroup>
          <FieldError errors={ customField?.errors?.group_name || [] }>
            <Label
              className='form-label'
              for={ inputName(formName, 'group_name') }
            >
              { I18n.t(`activerecord.attributes.custom_field.group_name`) }
            </Label>
            <Input
              type='text'
              name={ inputName(formName, 'group_name') }
              id={ inputId(formName, "group_name") }
              onChange={ (event) => onChangeCustomField({ target: { value: event?.value }}, "group_name") }
              value={ customField.group_name }
              invalid={ _.has(customField?.errors, 'group_name') }
            />
          </FieldError>
        </FormGroup>
      )
    }
  }

  const codeInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ customField?.errors?.code || [] }>
          <Label
            className='form-label'
            for={ inputName(formName, 'code') }
          >
            { I18n.t(`activerecord.attributes.custom_field.code`) }
          </Label>
          <Input
            type='text'
            name={ inputName(formName, 'code') }
            id={ inputId(formName, 'code') }
            defaultValue={ customField.code }
            disabled={ true }
            />
        </FieldError>
      </FormGroup>
    )
  }

  const dataTypeSelectInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ customField?.errors?.data_type || [] }>
          <Label
            for={ inputId(formName, 'data_type') }
          >
            {I18n.t(`activerecord.attributes.custom_field.data_type`)}
          </Label>
          <Select
            id={ inputId(formName, 'data_type') }
            name={ inputName(formName, 'data_type') }
            invalid={ _.has(customField?.errors, 'data_type') }
            options={ dataTypeOptions }
            value={ dataTypeSelectedOption }
            onChange={ (event) => onChangeCustomField({ target: { value: event?.value } }, "data_type") }
            placeholder={ `-- Selecciona una opción --` }
            styles={ selectStyle }
            className="mb-2"
          />
        </FieldError>
      </FormGroup>
    )
  }

  const listOptionsSelectInput = () => {
    if(dataTypeSelectedOption?.value === 'list'){
      return(
        <FormGroup>
          <FieldError errors={ customField?.errors?.list_options || [] }>
            <Label
              for={ inputId(formName, 'list_options') }
            >
              { I18n.t(`activerecord.attributes.custom_field.list_options`) }
            </Label>
            <CreatableSelect
              isMulti
              options={ listSelectOptions }
              formatCreateLabel={ (inputValue) => `Crear "${inputValue}"` }
              placeholder="Agregar..."
              styles={ selectStyle }
              defaultValue={ listSelectOptions }
              onChange={ (options)=>{ onChangeListOptions(options) } }
              name={ `${ inputName(formName, 'list_options')}[]` }
            />
          </FieldError>
        </FormGroup>
      )
    }
  }

  const notificationEmailSwitchInput = () => {
    if(dataTypeSelectedOption?.value === 'email' && context == "abstract"){
      return(
        <FormGroup>
          <div className='custom-switch'>
            <FieldError errors={ customField?.errors?.keep_format || [] }>
              <Input
                className='custom-control-input'
                type='checkbox'
                name={ inputName(formName, 'is_notification_email') }
                id={ inputId(formName, 'is_notification_email') }
                invalid={ _.has(customField?.errors, 'is_notification_email') }
                onChange={ (e) => onChangeCustomField(e, "is_notification_email") }
                value={ customField?.is_notification_email }
              />
              <Label
                className='custom-control-label'
                for={ inputId(formName, 'is_notification_email') }
              >
                { I18n.t(`activerecord.attributes.custom_field.is_notification_email`) }
                { drawTooltipHelp(I18n.t('abstract.custom_fields.is_notification_email.help')) }
              </Label>
            </FieldError>
          </div>
        </FormGroup>
      )
    }
  }

  const showAsNameSwitchInput = () =>{
    if(context == "abstract"){
      return(
        <FormGroup>
          <div className='custom-switch'>
            <FieldError errors={ customField?.errors?.show_as_name || [] }>
              <Input
                className='custom-control-input'
                type='checkbox'
                name={ inputName(formName, 'show_as_name') }
                id={ inputId(formName, 'show_as_name') }
                invalid={ _.has(customField?.errors, 'show_as_name') }
                onChange={ (e) => onChangeCustomField(e, "show_as_name") }
                checked={ customField?.show_as_name || false }
                value={ customField?.show_as_name }
              />
              <Label
                className='custom-control-label'
                for={ inputId(formName, 'show_as_name') }
              >
                { I18n.t(`activerecord.attributes.custom_field.show_as_name`) }
                { drawTooltipHelp(I18n.t('abstract.custom_fields.show_as_name.help')) }

              </Label>
            </FieldError>
          </div>
        </FormGroup>
      )
    }
  }

  const showOnListSwitchInput = () =>{
    return(
      <FormGroup>
        <div className='custom-switch'>
          <FieldError errors={ customField?.errors?.show_on_list || [] }>
            <Input
              className='custom-control-input'
              type='checkbox'
              name={ inputName(formName, 'show_on_list') }
              id={ inputId(formName, 'show_on_list') }
              invalid={ _.has(customField?.errors, 'show_on_list') }
              onChange={ (e) => onChangeCustomField(e, "show_on_list") }
              checked={ customField?.show_on_list || false }
              value={ customField?.show_on_list }
            />
            <Label
              className='custom-control-label'
              for={ inputId(formName, 'show_on_list') }
            >
              { I18n.t(`activerecord.attributes.custom_field.show_on_list`) }
              { drawTooltipHelp(I18n.t('abstract.custom_fields.show_on_list.help')) }
            </Label>
          </FieldError>
        </div>
      </FormGroup>
    )
  }

  return(
    <div className="card card-outline card-primary">
      <div className="card-body">
        <FormGroup>

          { resourceTypeInput() }
          { labelInput() }
          { groupNameInput() }
          { codeInput() }
          { dataTypeSelectInput() }
          { listOptionsSelectInput() }
          { notificationEmailSwitchInput() }
          { showAsNameSwitchInput() }
          { showOnListSwitchInput() }

        </FormGroup>
      </div>
    </div>
  )
}

export default CustomFieldForm