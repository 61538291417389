/**
 * File generated by js-routes 2.2.0
 * Based on Rails 6.1.4.1 routes of ControldocCompliance::Application
 */
const __jsr = ((that) => {
    const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
    let NodeTypes;
    (function (NodeTypes) {
        NodeTypes[NodeTypes["GROUP"] = 1] = "GROUP";
        NodeTypes[NodeTypes["CAT"] = 2] = "CAT";
        NodeTypes[NodeTypes["SYMBOL"] = 3] = "SYMBOL";
        NodeTypes[NodeTypes["OR"] = 4] = "OR";
        NodeTypes[NodeTypes["STAR"] = 5] = "STAR";
        NodeTypes[NodeTypes["LITERAL"] = 6] = "LITERAL";
        NodeTypes[NodeTypes["SLASH"] = 7] = "SLASH";
        NodeTypes[NodeTypes["DOT"] = 8] = "DOT";
    })(NodeTypes || (NodeTypes = {}));
    const Root = that;
    const isBroswer = typeof window !== "undefined";
    const ModuleReferences = {
        CJS: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                module.exports = routes;
            },
            isSupported() {
                return typeof module === "object";
            },
        },
        AMD: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                define([], function () {
                    return routes;
                });
            },
            isSupported() {
                return typeof define === "function" && !!define.amd;
            },
        },
        UMD: {
            define(routes) {
                if (ModuleReferences.AMD.isSupported()) {
                    ModuleReferences.AMD.define(routes);
                }
                else {
                    if (ModuleReferences.CJS.isSupported()) {
                        try {
                            ModuleReferences.CJS.define(routes);
                        }
                        catch (error) {
                            if (error.name !== "TypeError")
                                throw error;
                        }
                    }
                }
            },
            isSupported() {
                return (ModuleReferences.AMD.isSupported() ||
                    ModuleReferences.CJS.isSupported());
            },
        },
        ESM: {
            define() {
                // Module can only be defined using ruby code generation
            },
            isSupported() {
                // Its impossible to check if "export" keyword is supported
                return true;
            },
        },
        NIL: {
            define(routes) {
                Utils.namespace(Root, null, routes);
            },
            isSupported() {
                return !null || !!Root;
            },
        },
        DTS: {
            // Acts the same as ESM
            define(routes) {
                ModuleReferences.ESM.define(routes);
            },
            isSupported() {
                return ModuleReferences.ESM.isSupported();
            },
        },
    };
    class ParametersMissing extends Error {
        constructor(...keys) {
            super(`Route missing required keys: ${keys.join(", ")}`);
            this.keys = keys;
            Object.setPrototypeOf(this, Object.getPrototypeOf(this));
            this.name = ParametersMissing.name;
        }
    }
    const UriEncoderSegmentRegex = /[^a-zA-Z0-9\-._~!$&'()*+,;=:@]/g;
    const ReservedOptions = [
        "anchor",
        "trailing_slash",
        "subdomain",
        "host",
        "port",
        "protocol",
    ];
    class UtilsClass {
        constructor() {
            this.configuration = {
                prefix: "",
                default_url_options: {},
                special_options_key: "_options",
                serializer: null || this.default_serializer.bind(this),
            };
        }
        default_serializer(value, prefix) {
            if (this.is_nullable(value)) {
                return "";
            }
            if (!prefix && !this.is_object(value)) {
                throw new Error("Url parameters should be a javascript hash");
            }
            prefix = prefix || "";
            const result = [];
            if (this.is_array(value)) {
                for (const element of value) {
                    result.push(this.default_serializer(element, prefix + "[]"));
                }
            }
            else if (this.is_object(value)) {
                for (let key in value) {
                    if (!hasProp(value, key))
                        continue;
                    let prop = value[key];
                    if (this.is_nullable(prop) && prefix) {
                        prop = "";
                    }
                    if (this.is_not_nullable(prop)) {
                        if (prefix) {
                            key = prefix + "[" + key + "]";
                        }
                        result.push(this.default_serializer(prop, key));
                    }
                }
            }
            else {
                if (this.is_not_nullable(value)) {
                    result.push(encodeURIComponent(prefix) + "=" + encodeURIComponent("" + value));
                }
            }
            return result.join("&");
        }
        serialize(object) {
            return this.configuration.serializer(object);
        }
        extract_options(number_of_params, args) {
            const last_el = args[args.length - 1];
            if ((args.length > number_of_params && last_el === 0) ||
                (this.is_object(last_el) &&
                    !this.looks_like_serialized_model(last_el))) {
                if (this.is_object(last_el)) {
                    delete last_el[this.configuration.special_options_key];
                }
                return {
                    args: args.slice(0, args.length - 1),
                    options: last_el,
                };
            }
            else {
                return { args, options: {} };
            }
        }
        looks_like_serialized_model(object) {
            return (this.is_object(object) &&
                !(this.configuration.special_options_key in object) &&
                ("id" in object || "to_param" in object || "toParam" in object));
        }
        path_identifier(object) {
            const result = this.unwrap_path_identifier(object);
            return this.is_nullable(result) || result === false ? "" : "" + result;
        }
        unwrap_path_identifier(object) {
            let result = object;
            if (!this.is_object(object)) {
                return object;
            }
            if ("to_param" in object) {
                result = object.to_param;
            }
            else if ("toParam" in object) {
                result = object.toParam;
            }
            else if ("id" in object) {
                result = object.id;
            }
            else {
                result = object;
            }
            return this.is_callable(result) ? result.call(object) : result;
        }
        partition_parameters(parts, required_params, default_options, call_arguments) {
            // eslint-disable-next-line prefer-const
            let { args, options } = this.extract_options(parts.length, call_arguments);
            if (args.length > parts.length) {
                throw new Error("Too many parameters provided for path");
            }
            let use_all_parts = args.length > required_params.length;
            const parts_options = {
                ...this.configuration.default_url_options,
            };
            for (const key in options) {
                const value = options[key];
                if (!hasProp(options, key))
                    continue;
                use_all_parts = true;
                if (parts.includes(key)) {
                    parts_options[key] = value;
                }
            }
            options = {
                ...this.configuration.default_url_options,
                ...default_options,
                ...options,
            };
            const keyword_parameters = {};
            const query_parameters = {};
            for (const key in options) {
                if (!hasProp(options, key))
                    continue;
                const value = options[key];
                if (this.is_reserved_option(key)) {
                    keyword_parameters[key] = value;
                }
                else {
                    if (!this.is_nullable(value) &&
                        (value !== default_options[key] || required_params.includes(key))) {
                        query_parameters[key] = value;
                    }
                }
            }
            const route_parts = use_all_parts ? parts : required_params;
            let i = 0;
            for (const part of route_parts) {
                if (i < args.length) {
                    const value = args[i];
                    if (!hasProp(parts_options, part)) {
                        query_parameters[part] = value;
                        ++i;
                    }
                }
            }
            return { keyword_parameters, query_parameters };
        }
        build_route(parts, required_params, default_options, route, absolute, args) {
            const { keyword_parameters, query_parameters, } = this.partition_parameters(parts, required_params, default_options, args);
            const missing_params = required_params.filter((param) => !hasProp(query_parameters, param) ||
                this.is_nullable(query_parameters[param]));
            if (missing_params.length) {
                throw new ParametersMissing(...missing_params);
            }
            let result = this.get_prefix() + this.visit(route, query_parameters);
            if (keyword_parameters.trailing_slash) {
                result = result.replace(/(.*?)[/]?$/, "$1/");
            }
            const url_params = this.serialize(query_parameters);
            if (url_params.length) {
                result += "?" + url_params;
            }
            result += keyword_parameters.anchor
                ? "#" + keyword_parameters.anchor
                : "";
            if (absolute) {
                result = this.route_url(keyword_parameters) + result;
            }
            return result;
        }
        visit(route, parameters, optional = false) {
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return this.visit(route[1], parameters, true);
                case NodeTypes.CAT:
                    return this.visit_cat(route, parameters, optional);
                case NodeTypes.SYMBOL:
                    return this.visit_symbol(route, parameters, optional);
                case NodeTypes.STAR:
                    return this.visit_globbing(route[1], parameters, true);
                case NodeTypes.LITERAL:
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        is_not_nullable(object) {
            return !this.is_nullable(object);
        }
        is_nullable(object) {
            return object === undefined || object === null;
        }
        visit_cat(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, left, right], parameters, optional) {
            const left_part = this.visit(left, parameters, optional);
            let right_part = this.visit(right, parameters, optional);
            if (optional &&
                ((this.is_optional_node(left[0]) && !left_part) ||
                    (this.is_optional_node(right[0]) && !right_part))) {
                return "";
            }
            // if left_part ends on '/' and right_part starts on '/'
            if (left_part[left_part.length - 1] === "/" && right_part[0] === "/") {
                // strip slash from right_part
                // to prevent double slash
                right_part = right_part.substring(1);
            }
            return left_part + right_part;
        }
        visit_symbol(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, key], parameters, optional) {
            const value = this.path_identifier(parameters[key]);
            delete parameters[key];
            if (value.length) {
                return this.encode_segment(value);
            }
            if (optional) {
                return "";
            }
            else {
                throw new ParametersMissing(key);
            }
        }
        encode_segment(segment) {
            return segment.replace(UriEncoderSegmentRegex, (str) => encodeURIComponent(str));
        }
        is_optional_node(node) {
            return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
        }
        build_path_spec(route, wildcard = false) {
            let key;
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return "(" + this.build_path_spec(route[1]) + ")";
                case NodeTypes.CAT:
                    return (this.build_path_spec(route[1]) + this.build_path_spec(route[2]));
                case NodeTypes.STAR:
                    return this.build_path_spec(route[1], true);
                case NodeTypes.SYMBOL:
                    key = route[1];
                    if (wildcard) {
                        return (key.startsWith("*") ? "" : "*") + key;
                    }
                    else {
                        return ":" + key;
                    }
                    break;
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                case NodeTypes.LITERAL:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        visit_globbing(route, parameters, optional) {
            const key = route[1];
            let value = parameters[key];
            delete parameters[key];
            if (this.is_nullable(value)) {
                return this.visit(route, parameters, optional);
            }
            if (this.is_array(value)) {
                value = value.join("/");
            }
            const result = this.path_identifier(value);
            return false
                ? result
                : encodeURI(result);
        }
        get_prefix() {
            const prefix = this.configuration.prefix;
            return prefix.match("/$")
                ? prefix.substring(0, prefix.length - 1)
                : prefix;
        }
        route(parts_table, route_spec, absolute = false) {
            const required_params = [];
            const parts = [];
            const default_options = {};
            for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
                parts.push(part);
                if (required) {
                    required_params.push(part);
                }
                if (this.is_not_nullable(value)) {
                    default_options[part] = value;
                }
            }
            const result = (...args) => {
                return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
            };
            result.requiredParams = () => required_params;
            result.toString = () => {
                return this.build_path_spec(route_spec);
            };
            return result;
        }
        route_url(route_defaults) {
            const hostname = route_defaults.host || this.current_host();
            if (!hostname) {
                return "";
            }
            const subdomain = route_defaults.subdomain
                ? route_defaults.subdomain + "."
                : "";
            const protocol = route_defaults.protocol || this.current_protocol();
            let port = route_defaults.port ||
                (!route_defaults.host ? this.current_port() : undefined);
            port = port ? ":" + port : "";
            return protocol + "://" + subdomain + hostname + port;
        }
        current_host() {
            var _a;
            return (isBroswer && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname)) || "";
        }
        current_protocol() {
            var _a, _b;
            return ((isBroswer && ((_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.protocol) === null || _b === void 0 ? void 0 : _b.replace(/:$/, ""))) || "http");
        }
        current_port() {
            var _a;
            return (isBroswer && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.port)) || "";
        }
        is_object(value) {
            return (typeof value === "object" &&
                Object.prototype.toString.call(value) === "[object Object]");
        }
        is_array(object) {
            return object instanceof Array;
        }
        is_callable(object) {
            return typeof object === "function" && !!object.call;
        }
        is_reserved_option(key) {
            return ReservedOptions.includes(key);
        }
        namespace(object, namespace, routes) {
            const parts = (namespace === null || namespace === void 0 ? void 0 : namespace.split(".")) || [];
            if (parts.length === 0) {
                return;
            }
            for (let index = 0; index < parts.length; index++) {
                const part = parts[index];
                if (index < parts.length - 1) {
                    object = object[part] || (object[part] = {});
                }
                else {
                    object[part] = routes;
                }
            }
        }
        configure(new_config) {
            this.configuration = { ...this.configuration, ...new_config };
            return this.configuration;
        }
        config() {
            return { ...this.configuration };
        }
        is_module_supported(name) {
            return ModuleReferences[name].isSupported();
        }
        ensure_module_supported(name) {
            if (!this.is_module_supported(name)) {
                throw new Error(`${name} is not supported by runtime`);
            }
        }
        define_module(name, module) {
            this.ensure_module_supported(name);
            ModuleReferences[name].define(module);
        }
    }
    const Utils = new UtilsClass();
    // We want this helper name to be short
    const __jsr = {
        r(parts_table, route_spec, absolute) {
            return Utils.route(parts_table, route_spec, absolute);
        },
    };
    const result = {
        ...__jsr,
        configure: (config) => {
            return Utils.configure(config);
        },
        config: () => {
            return Utils.config();
        },
        serialize: (object) => {
            return Utils.serialize(object);
        },
        ...{},
    };
    Utils.define_module("ESM", result);
    return result;
})(this);
export const configure = __jsr.configure;

export const config = __jsr.config;

export const serialize = __jsr.serialize;

/**
 * Generates rails route to
 * /module/:current_entity_type_id/bulk/load/documents/:id(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_bulk_load_document_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"load"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/bulk/load/documents(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_bulk_load_documents_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"load"],[2,[7,"/"],[2,[6,"documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/bulk/load/entities(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_bulk_load_entities_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"load"],[2,[7,"/"],[2,[6,"entities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/bulk/load/entities/:id(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_bulk_load_entity_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"load"],[2,[7,"/"],[2,[6,"entities"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/bulk/request/documents/:id(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_bulk_request_document_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"request"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/bulk/request/documents(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_bulk_request_documents_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"request"],[2,[7,"/"],[2,[6,"documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/bulk/request/entity_items/:id(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_bulk_request_entity_item_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"request"],[2,[7,"/"],[2,[6,"entity_items"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/bulk/request/zip/documents/:id(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_bulk_request_zip_document_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"request"],[2,[7,"/"],[2,[6,"zip"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/bulk/request/zip/documents(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_bulk_request_zip_documents_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"request"],[2,[7,"/"],[2,[6,"zip"],[2,[7,"/"],[2,[6,"documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/check_list/processes/:id(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_check_list_process_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"check_list"],[2,[7,"/"],[2,[6,"processes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/check_list/processes(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_check_list_processes_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"check_list"],[2,[7,"/"],[2,[6,"processes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/check_list/tasks/:id(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_check_list_task_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"check_list"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/check_list/tasks(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_check_list_tasks_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"check_list"],[2,[7,"/"],[2,[6,"tasks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/controldoc_form/documents/:id(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_controldoc_form_document_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/controldoc_form/documents(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_controldoc_form_documents_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/controldoc_form/templates/:id(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_controldoc_form_template_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"templates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/controldoc_form/template_layouts/:id(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_controldoc_form_template_layout_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"template_layouts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/controldoc_form/template_layouts(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_controldoc_form_template_layouts_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"template_layouts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/controldoc_form/templates(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_controldoc_form_templates_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"templates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/controldoc_sign/document/meta_informations/:id(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_controldoc_sign_document_meta_information_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"controldoc_sign"],[2,[7,"/"],[2,[6,"document"],[2,[7,"/"],[2,[6,"meta_informations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/controldoc_sign/document/meta_informations(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_controldoc_sign_document_meta_informations_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"controldoc_sign"],[2,[7,"/"],[2,[6,"document"],[2,[7,"/"],[2,[6,"meta_informations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/custom_fields/:id(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_custom_field_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"custom_fields"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/custom_fields(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_custom_fields_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"custom_fields"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/documents/:id(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_document_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/document_types/:id(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_document_type_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"document_types"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/document_type_batteries(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_document_type_batteries_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"document_type_batteries"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/document_type_batteries/:id(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_document_type_battery_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"document_type_batteries"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/document_types(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_document_types_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"document_types"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/documents(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_documents_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/entities(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_entities_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"entities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/entities/:id(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_entity_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"entities"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/entity_folder/check_lists/:id(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_entity_folder_check_list_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"entity_folder"],[2,[7,"/"],[2,[6,"check_lists"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/entity_folder/check_list_requirements/:id(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_entity_folder_check_list_requirement_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"entity_folder"],[2,[7,"/"],[2,[6,"check_list_requirements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/entity_folder/check_list_requirements(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_entity_folder_check_list_requirements_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"entity_folder"],[2,[7,"/"],[2,[6,"check_list_requirements"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/entity_folder/check_list_shareds/:id(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_entity_folder_check_list_shared_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"entity_folder"],[2,[7,"/"],[2,[6,"check_list_shareds"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/entity_folder/check_list_shareds(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_entity_folder_check_list_shareds_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"entity_folder"],[2,[7,"/"],[2,[6,"check_list_shareds"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/entity_folder/check_list_tasks/:id(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_entity_folder_check_list_task_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"entity_folder"],[2,[7,"/"],[2,[6,"check_list_tasks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/entity_folder/check_list_tasks(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_entity_folder_check_list_tasks_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"entity_folder"],[2,[7,"/"],[2,[6,"check_list_tasks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/entity_folder/check_lists(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_entity_folder_check_lists_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"entity_folder"],[2,[7,"/"],[2,[6,"check_lists"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/fao/mapping_configs/:id(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_fao_mapping_config_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"fao"],[2,[7,"/"],[2,[6,"mapping_configs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/fao/mapping_configs(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_fao_mapping_configs_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"fao"],[2,[7,"/"],[2,[6,"mapping_configs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/report/documents/:id(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_report_document_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/report/documents(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_report_documents_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/report/signers/:id(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_report_signer_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"signers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/report/signers(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_report_signers_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"signers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/shortlist/assessment_loads/:id(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_shortlist_assessment_load_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"shortlist"],[2,[7,"/"],[2,[6,"assessment_loads"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/shortlist/assessment_loads(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_shortlist_assessment_loads_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"shortlist"],[2,[7,"/"],[2,[6,"assessment_loads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/workflow/edges/:id(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_workflow_edge_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"edges"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/workflow/edges(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_workflow_edges_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"edges"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/workflow/groups(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_workflow_groups_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"groups"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/workflow/processes/:id(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_workflow_process_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"processes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/workflow/processes(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_workflow_processes_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"processes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/workflow/requirements/:id(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_workflow_requirement_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"requirements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/workflow/requirement_stages/:id(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_workflow_requirement_stage_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"requirement_stages"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/workflow/requirement_stages(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_workflow_requirement_stages_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"requirement_stages"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/workflow/requirements(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_workflow_requirements_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"requirements"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/workflow/stages/:id(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_workflow_stage_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"stages"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/workflow/stages(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const abstract_workflow_stages_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"stages"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/invitation/accept(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accept_user_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"invitation"],[2,[7,"/"],[2,[6,"accept"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/abstract_custom_fields/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_abstract_custom_field_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"abstract_custom_fields"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/abstract_entity_types/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_abstract_entity_type_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"abstract_entity_types"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/abstract_entity_type_configs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_abstract_entity_type_config_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"abstract_entity_type_configs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/abstract_entity_type_configs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_abstract_entity_type_configs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"abstract_entity_type_configs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/abstract_entity_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_abstract_entity_types_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"abstract_entity_types"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/admin_users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"admin_users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/admin_users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_admin_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"admin_users"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing_invoices/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_billing_invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing_invoices"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing_invoices(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_billing_invoices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing_invoices"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/blog_posts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_blog_post_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"blog_posts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/blog_posts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_blog_posts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"blog_posts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/campaign_referral_users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_campaign_referral_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"campaign_referral_users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/campaign_referral_users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_campaign_referral_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"campaign_referral_users"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/change_logs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_change_log_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"change_logs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/change_logs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_change_logs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"change_logs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/comments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_comment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"comments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/comments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_comments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"comments"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/crm_post_sale_interviews/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_crm_post_sale_interview_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"crm_post_sale_interviews"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/crm_post_sale_interviews(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_crm_post_sale_interviews_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"crm_post_sale_interviews"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/crm_post_sale_tasks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_crm_post_sale_task_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"crm_post_sale_tasks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/crm_post_sale_tasks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_crm_post_sale_tasks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"crm_post_sale_tasks"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/crm_pre_sale_interviews/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_crm_pre_sale_interview_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"crm_pre_sale_interviews"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/crm_pre_sale_interviews(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_crm_pre_sale_interviews_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"crm_pre_sale_interviews"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/crm_pre_sale_tasks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_crm_pre_sale_task_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"crm_pre_sale_tasks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/crm_pre_sale_tasks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_crm_pre_sale_tasks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"crm_pre_sale_tasks"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/crm_task_reports/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_crm_task_report_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"crm_task_reports"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/crm_task_reports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_crm_task_reports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"crm_task_reports"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/crm_tech_tasks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_crm_tech_task_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"crm_tech_tasks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/crm_tech_tasks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_crm_tech_tasks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"crm_tech_tasks"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/customers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_customer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/customer_onboarding_processes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_customer_onboarding_process_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"customer_onboarding_processes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/customer_onboarding_processes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_customer_onboarding_processes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"customer_onboarding_processes"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/customer_service_suspensions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_customer_service_suspension_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"customer_service_suspensions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/customer_service_suspensions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_customer_service_suspensions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"customer_service_suspensions"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/customers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_customers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"customers"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/nps_records/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_nps_record_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"nps_records"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/nps_records(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_nps_records_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"nps_records"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/nps_results/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_nps_result_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"nps_results"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/nps_results(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_nps_results_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"nps_results"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/page_documentations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_page_documentation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"page_documentations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/page_documentations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_page_documentations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"page_documentations"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/products/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_product_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/products(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_products_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"products"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/roles/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_role_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"roles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/roles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_roles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"roles"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_root_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/system_notifications/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_system_notification_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"system_notifications"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/system_notifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_system_notifications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"system_notifications"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/login(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"login"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/abstract/custom_fields(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_abstract_custom_fields_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"abstract"],[2,[7,"/"],[2,[6,"custom_fields"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/abstract/documents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_abstract_document_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"abstract"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/abstract/document_types/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_abstract_document_type_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"abstract"],[2,[7,"/"],[2,[6,"document_types"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/abstract/document_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_abstract_document_types_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"abstract"],[2,[7,"/"],[2,[6,"document_types"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/abstract/documents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_abstract_documents_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"abstract"],[2,[7,"/"],[2,[6,"documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/abstract/entities(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_abstract_entities_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"abstract"],[2,[7,"/"],[2,[6,"entities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/abstract/entities/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_abstract_entity_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"abstract"],[2,[7,"/"],[2,[6,"entities"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/abstract/entity_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_abstract_entity_types_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"abstract"],[2,[7,"/"],[2,[6,"entity_types"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/corporate/agreements/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_corporate_agreement_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"agreements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/corporate/agreement_documents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_corporate_agreement_document_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"agreement_documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/corporate/agreement_documents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_corporate_agreement_documents_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"agreement_documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/corporate/agreements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_corporate_agreements_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"agreements"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/corporate/companies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_corporate_companies_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"companies"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/corporate/companies/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_corporate_company_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/corporate/custom_fields(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_corporate_custom_fields_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"custom_fields"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/corporate/document_types/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_corporate_document_type_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"document_types"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/corporate/document_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_corporate_document_types_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"document_types"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/laboral/custom_fields(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_laboral_custom_fields_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"custom_fields"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/laboral/document_types/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_laboral_document_type_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"document_types"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/laboral/document_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_laboral_document_types_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"document_types"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/laboral/employees/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_laboral_employee_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"employees"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/laboral/employees(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_laboral_employees_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"employees"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/laboral/hirings/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_laboral_hiring_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"hirings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/laboral/hiring_documents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_laboral_hiring_document_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"hiring_documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/laboral/hiring_documents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_laboral_hiring_documents_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"hiring_documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/laboral/hirings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_laboral_hirings_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"hirings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/laboral/security_layers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_laboral_security_layers_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"security_layers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/controldoc_form/templates/:id/assign_mapping_links(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const assign_mapping_links_abstract_controldoc_form_template_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"assign_mapping_links"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/controldoc_form/templates/:id/assign_mapping_links(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const assign_mapping_links_corporate_controldoc_form_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"assign_mapping_links"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/controldoc_form/templates/:id/assign_mapping_links(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const assign_mapping_links_laboral_controldoc_form_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"assign_mapping_links"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/abstract/workflow/requirements/:customer_id/:process_id/:id/authenticate(.:format)
 * @param {any} customer_id
 * @param {any} process_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const authenticate_public_abstract_workflow_requirement_path = __jsr.r({"customer_id":{"r":true},"process_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"abstract"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"requirements"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[3,"process_id"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"authenticate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/ley_karin/denuncia/:customer_id/:process_id/:id/authenticate(.:format)
 * @param {any} customer_id
 * @param {any} process_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const authenticate_public_karin_law_workflow_requirement_path = __jsr.r({"customer_id":{"r":true},"process_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"ley_karin"],[2,[7,"/"],[2,[6,"denuncia"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[3,"process_id"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"authenticate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/entity_folder/check_list_shareds/:id/authenticate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const authenticate_shared_entity_folder_check_list_shared_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"entity_folder"],[2,[7,"/"],[2,[6,"check_list_shareds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"authenticate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/abstract/workflow/requirements/:customer_id/:process_id/:id/authentication(.:format)
 * @param {any} customer_id
 * @param {any} process_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const authentication_public_abstract_workflow_requirement_path = __jsr.r({"customer_id":{"r":true},"process_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"abstract"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"requirements"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[3,"process_id"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"authentication"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/ley_karin/denuncia/:customer_id/:process_id/:id/authentication(.:format)
 * @param {any} customer_id
 * @param {any} process_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const authentication_public_karin_law_workflow_requirement_path = __jsr.r({"customer_id":{"r":true},"process_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"ley_karin"],[2,[7,"/"],[2,[6,"denuncia"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[3,"process_id"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"authentication"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/entity_folder/check_list_shareds/:id/authentication(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const authentication_shared_entity_folder_check_list_shared_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"entity_folder"],[2,[7,"/"],[2,[6,"check_list_shareds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"authentication"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/abstract_custom_fields/batch_action(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_action_admin_abstract_custom_fields_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"abstract_custom_fields"],[2,[7,"/"],[2,[6,"batch_action"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/abstract_entity_type_configs/batch_action(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_action_admin_abstract_entity_type_configs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"abstract_entity_type_configs"],[2,[7,"/"],[2,[6,"batch_action"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/abstract_entity_types/batch_action(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_action_admin_abstract_entity_types_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"abstract_entity_types"],[2,[7,"/"],[2,[6,"batch_action"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/admin_users/batch_action(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_action_admin_admin_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"admin_users"],[2,[7,"/"],[2,[6,"batch_action"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/billing_invoices/batch_action(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_action_admin_billing_invoices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"billing_invoices"],[2,[7,"/"],[2,[6,"batch_action"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/blog_posts/batch_action(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_action_admin_blog_posts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"blog_posts"],[2,[7,"/"],[2,[6,"batch_action"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/campaign_referral_users/batch_action(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_action_admin_campaign_referral_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"campaign_referral_users"],[2,[7,"/"],[2,[6,"batch_action"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/change_logs/batch_action(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_action_admin_change_logs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"change_logs"],[2,[7,"/"],[2,[6,"batch_action"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/crm_post_sale_interviews/batch_action(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_action_admin_crm_post_sale_interviews_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"crm_post_sale_interviews"],[2,[7,"/"],[2,[6,"batch_action"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/crm_post_sale_tasks/batch_action(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_action_admin_crm_post_sale_tasks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"crm_post_sale_tasks"],[2,[7,"/"],[2,[6,"batch_action"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/crm_pre_sale_interviews/batch_action(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_action_admin_crm_pre_sale_interviews_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"crm_pre_sale_interviews"],[2,[7,"/"],[2,[6,"batch_action"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/crm_pre_sale_tasks/batch_action(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_action_admin_crm_pre_sale_tasks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"crm_pre_sale_tasks"],[2,[7,"/"],[2,[6,"batch_action"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/crm_task_reports/batch_action(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_action_admin_crm_task_reports_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"crm_task_reports"],[2,[7,"/"],[2,[6,"batch_action"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/crm_tech_tasks/batch_action(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_action_admin_crm_tech_tasks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"crm_tech_tasks"],[2,[7,"/"],[2,[6,"batch_action"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/customer_onboarding_processes/batch_action(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_action_admin_customer_onboarding_processes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"customer_onboarding_processes"],[2,[7,"/"],[2,[6,"batch_action"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/customer_service_suspensions/batch_action(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_action_admin_customer_service_suspensions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"customer_service_suspensions"],[2,[7,"/"],[2,[6,"batch_action"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/customers/batch_action(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_action_admin_customers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"batch_action"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/nps_records/batch_action(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_action_admin_nps_records_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"nps_records"],[2,[7,"/"],[2,[6,"batch_action"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/nps_results/batch_action(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_action_admin_nps_results_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"nps_results"],[2,[7,"/"],[2,[6,"batch_action"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/page_documentations/batch_action(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_action_admin_page_documentations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"page_documentations"],[2,[7,"/"],[2,[6,"batch_action"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/products/batch_action(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_action_admin_products_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"batch_action"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/roles/batch_action(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_action_admin_roles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"roles"],[2,[7,"/"],[2,[6,"batch_action"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/system_notifications/batch_action(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_action_admin_system_notifications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"system_notifications"],[2,[7,"/"],[2,[6,"batch_action"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/batch_action(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_action_admin_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"batch_action"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/accounts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_account_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/quotes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const billing_quotes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"quotes"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /blazer
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_path = __jsr.r({}, [2,[7,"/"],[6,"blazer"]]);

/**
 * Generates rails route to
 * /blazer/queries/run(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_run_queries_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"queries"],[2,[7,"/"],[2,[6,"run"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /blazer/queries/cancel(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_cancel_queries_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"queries"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /blazer/queries/:id/refresh(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_refresh_query_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"queries"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"refresh"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /blazer/queries/tables(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_tables_queries_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"queries"],[2,[7,"/"],[2,[6,"tables"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /blazer/queries/schema(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_schema_queries_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"queries"],[2,[7,"/"],[2,[6,"schema"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /blazer/queries/docs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_docs_queries_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"queries"],[2,[7,"/"],[2,[6,"docs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /blazer/queries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_queries_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"queries"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /blazer/queries/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_new_query_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"queries"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /blazer/queries/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_edit_query_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"queries"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /blazer/queries/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_query_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"queries"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /blazer/checks/:id/run(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_run_check_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"checks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"run"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /blazer/checks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_checks_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"checks"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /blazer/checks/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_new_check_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"checks"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /blazer/checks/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_edit_check_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"checks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /blazer/checks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_check_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"checks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /blazer/dashboards/:id/refresh(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_refresh_dashboard_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"dashboards"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"refresh"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /blazer/dashboards(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_dashboards_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"dashboards"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /blazer/dashboards/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_new_dashboard_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"dashboards"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /blazer/dashboards/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_edit_dashboard_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"dashboards"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /blazer/dashboards/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_dashboard_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"blazer"]],[7,"/"]],[2,[6,"dashboards"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /blazer/
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blazer_root_path = __jsr.r({}, [2,[2,[7,"/"],[6,"blazer"]],[7,"/"]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/controldoc_form/documents/:id/block(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const block_abstract_controldoc_form_document_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"block"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/documents/:id/block(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const block_abstract_document_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"block"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/abstract/documents/:id/block(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const block_api_v1_abstract_document_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"abstract"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"block"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/corporate/agreement_documents/:id/block(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const block_api_v1_corporate_agreement_document_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"agreement_documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"block"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/laboral/hiring_documents/:id/block(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const block_api_v1_laboral_hiring_document_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"hiring_documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"block"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/agreement_documents/:id/block(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const block_corporate_agreement_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"agreement_documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"block"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/controldoc_form/documents/:id/block(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const block_corporate_controldoc_form_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"block"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/controldoc_form/documents/:id/block(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const block_laboral_controldoc_form_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"block"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/hiring_documents/:id/block(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const block_laboral_hiring_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"hiring_documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"block"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /blog(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blog_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"blog"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /blog/posts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blog_post_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"blog"],[2,[7,"/"],[2,[6,"posts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /portal_access/documents/bulk_document_sign(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulk_document_sign_portal_access_documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"portal_access"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"bulk_document_sign"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /bulk_load_tags/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulk_load_tag_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"bulk_load_tags"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /bulk_load_tags(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulk_load_tags_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"bulk_load_tags"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /p/:tenant/sign_documents/bulk_sign(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulk_sign_portal_sign_documents_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"sign_documents"],[2,[7,"/"],[2,[6,"bulk_sign"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /totem/documents/bulk_sign(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulk_sign_totem_documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"totem"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"bulk_sign"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /campaign/referral_users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const campaign_referral_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"campaign"],[2,[7,"/"],[2,[6,"referral_users"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /canal-de-denuncias(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const canal_de_denuncias_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"canal-de-denuncias"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /changelogs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const changelog_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"changelogs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/entities/clone/:id(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const clone_abstract_entities_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"entities"],[2,[7,"/"],[2,[6,"clone"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/entity_folder/check_list_requirements/:id/clone(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const clone_abstract_entity_folder_check_list_requirement_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"entity_folder"],[2,[7,"/"],[2,[6,"check_list_requirements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"clone"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/entity_folder/check_list_shareds/:id/requirement/:folder_id/clone/:requirement_id(.:format)
 * @param {any} id
 * @param {any} folder_id
 * @param {any} requirement_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const clone_requirement_shared_entity_folder_check_list_shared_path = __jsr.r({"id":{"r":true},"folder_id":{"r":true},"requirement_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"entity_folder"],[2,[7,"/"],[2,[6,"check_list_shareds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"requirement"],[2,[7,"/"],[2,[3,"folder_id"],[2,[7,"/"],[2,[6,"clone"],[2,[7,"/"],[2,[3,"requirement_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /comment/workflow/requirements/:resource_id(.:format)
 * @param {any} resource_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const comment_workflow_requirements_path = __jsr.r({"resource_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"comment"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"requirements"],[2,[7,"/"],[2,[3,"resource_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /como-usarlo(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const como_usarlo_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"como-usarlo"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /contratistas(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const contratistas_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"contratistas"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v1/document/callbacks/:document_id/controldoc(.:format)
 * @param {any} document_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const controldoc_api_v1_document_callback_path = __jsr.r({"document_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"document"],[2,[7,"/"],[2,[6,"callbacks"],[2,[7,"/"],[2,[3,"document_id"],[2,[7,"/"],[2,[6,"controldoc"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /controldoc_form/images/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const controldoc_form_image_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"images"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /controldoc_form/images(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const controldoc_form_images_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"images"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /corporate/agreements/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const corporate_agreement_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"agreements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/agreement_compliance_processes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const corporate_agreement_compliance_process_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"agreement_compliance_processes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/agreement_compliance_processes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const corporate_agreement_compliance_processes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"agreement_compliance_processes"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /corporate/agreement_compliance_requirements/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const corporate_agreement_compliance_requirement_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"agreement_compliance_requirements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/agreement_compliance_requirements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const corporate_agreement_compliance_requirements_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"agreement_compliance_requirements"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /corporate/agreement_documents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const corporate_agreement_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"agreement_documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/agreement_documents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const corporate_agreement_documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"agreement_documents"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /corporate/agreements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const corporate_agreements_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"agreements"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /corporate/bulk/notification/signs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const corporate_bulk_notification_sign_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"notification"],[2,[7,"/"],[2,[6,"signs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/bulk/notification/signs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const corporate_bulk_notification_signs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"notification"],[2,[7,"/"],[2,[6,"signs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/bulk/request/agreement_documents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const corporate_bulk_request_agreement_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"request"],[2,[7,"/"],[2,[6,"agreement_documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/bulk/request/agreement_documents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const corporate_bulk_request_agreement_documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"request"],[2,[7,"/"],[2,[6,"agreement_documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/bulk/request/agreement_items/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const corporate_bulk_request_agreement_item_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"request"],[2,[7,"/"],[2,[6,"agreement_items"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/bulk/request/zip/documents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const corporate_bulk_request_zip_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"request"],[2,[7,"/"],[2,[6,"zip"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/bulk/request/zip/documents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const corporate_bulk_request_zip_documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"request"],[2,[7,"/"],[2,[6,"zip"],[2,[7,"/"],[2,[6,"documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/check_list/agreement_processes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const corporate_check_list_agreement_process_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"check_list"],[2,[7,"/"],[2,[6,"agreement_processes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/check_list/agreement_processes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const corporate_check_list_agreement_processes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"check_list"],[2,[7,"/"],[2,[6,"agreement_processes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/check_list/agreement_tasks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const corporate_check_list_agreement_task_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"check_list"],[2,[7,"/"],[2,[6,"agreement_tasks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/check_list/agreement_tasks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const corporate_check_list_agreement_tasks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"check_list"],[2,[7,"/"],[2,[6,"agreement_tasks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/companies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const corporate_companies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"companies"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /corporate/companies/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const corporate_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/controldoc_form/documents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const corporate_controldoc_form_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/controldoc_form/documents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const corporate_controldoc_form_documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/controldoc_form/templates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const corporate_controldoc_form_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"templates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/controldoc_form/template_layouts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const corporate_controldoc_form_template_layout_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"template_layouts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/controldoc_form/template_layouts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const corporate_controldoc_form_template_layouts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"template_layouts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/controldoc_form/templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const corporate_controldoc_form_templates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"templates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/custom_fields/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const corporate_custom_field_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"custom_fields"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/custom_fields(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const corporate_custom_fields_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"custom_fields"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /corporate/document_types/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const corporate_document_type_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"document_types"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/document_type_batteries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const corporate_document_type_batteries_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"document_type_batteries"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /corporate/document_type_batteries/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const corporate_document_type_battery_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"document_type_batteries"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/document_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const corporate_document_types_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"document_types"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /corporativo(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const corporativo_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"corporativo"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /api/v1/laboral/hiring_documents/create_by_identifier(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_by_identifier_api_v1_laboral_hiring_documents_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"hiring_documents"],[2,[7,"/"],[2,[6,"create_by_identifier"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/entity_folder/check_list_shareds/:id/requirement/:folder_id(.:format)
 * @param {any} id
 * @param {any} folder_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_requirement_shared_entity_folder_check_list_shared_path = __jsr.r({"id":{"r":true},"folder_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"entity_folder"],[2,[7,"/"],[2,[6,"check_list_shareds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"requirement"],[2,[7,"/"],[2,[3,"folder_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/abstract/workflow/requirements/:customer_id/:process_id/:id/created(.:format)
 * @param {any} customer_id
 * @param {any} process_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const created_public_abstract_workflow_requirement_path = __jsr.r({"customer_id":{"r":true},"process_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"abstract"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"requirements"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[3,"process_id"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"created"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/ley_karin/denuncia/:customer_id/:process_id/:id/created(.:format)
 * @param {any} customer_id
 * @param {any} process_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const created_public_karin_law_workflow_requirement_path = __jsr.r({"customer_id":{"r":true},"process_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"ley_karin"],[2,[7,"/"],[2,[6,"denuncia"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[3,"process_id"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"created"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /orgs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"orgs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /customer_onboarding_processes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_onboarding_process_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer_onboarding_processes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /orgs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orgs"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /default_signers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const default_signer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"default_signers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /default_signers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const default_signers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"default_signers"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/logout(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_admin_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"logout"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /document_integration/controldoc_forms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const document_integration_controldoc_forms_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"document_integration"],[2,[7,"/"],[2,[6,"controldoc_forms"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /document_integration/controldoc_signs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const document_integration_controldoc_signs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"document_integration"],[2,[7,"/"],[2,[6,"controldoc_signs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /portal_access/documents/documents_enable_to_sign(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const documents_enable_to_sign_portal_access_documents_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"portal_access"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"documents_enable_to_sign"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/bulk/load/entities/:id/download(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_abstract_bulk_load_entity_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"load"],[2,[7,"/"],[2,[6,"entities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/bulk/request/zip/documents/:id/download/:attachment_id(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {any} attachment_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_abstract_bulk_request_zip_document_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"attachment_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"request"],[2,[7,"/"],[2,[6,"zip"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[2,[7,"/"],[2,[3,"attachment_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/documents/:id/download(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_abstract_document_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/entities/:id/download(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_abstract_entity_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"entities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/nps_records/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_admin_nps_record_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"nps_records"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /comment/workflow/requirements/:resource_id/:id/download/:attach_id(.:format)
 * @param {any} resource_id
 * @param {any} id
 * @param {any} attach_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_attach_comment_workflow_requirement_path = __jsr.r({"resource_id":{"r":true},"id":{"r":true},"attach_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"comment"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"requirements"],[2,[7,"/"],[2,[3,"resource_id"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[2,[7,"/"],[2,[3,"attach_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/:tenant/comment/workflow/requirements/:resource_id/:id/download/:attach_id(.:format)
 * @param {any} tenant
 * @param {any} resource_id
 * @param {any} id
 * @param {any} attach_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_attach_portal_comment_workflow_requirement_path = __jsr.r({"tenant":{"r":true},"resource_id":{"r":true},"id":{"r":true},"attach_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"comment"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"requirements"],[2,[7,"/"],[2,[3,"resource_id"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[2,[7,"/"],[2,[3,"attach_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/comment/workflow/requirements/:resource_id/:customer_id/:id/download/:attach_id(.:format)
 * @param {any} resource_id
 * @param {any} customer_id
 * @param {any} id
 * @param {any} attach_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_attach_public_comment_workflow_requirement_path = __jsr.r({"resource_id":{"r":true},"customer_id":{"r":true},"id":{"r":true},"attach_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"comment"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"requirements"],[2,[7,"/"],[2,[3,"resource_id"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[2,[7,"/"],[2,[3,"attach_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/abstract/documents/:id/download_base64(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_base64_api_v1_abstract_document_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"abstract"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download_base64"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/corporate/agreement_documents/:id/download_base64(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_base64_api_v1_corporate_agreement_document_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"agreement_documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download_base64"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/laboral/hiring_documents/:id/download_base64(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_base64_api_v1_laboral_hiring_document_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"hiring_documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download_base64"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/quote/resources/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_billing_quote_resource_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"quote"],[2,[7,"/"],[2,[6,"resources"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /bulk_load_tags/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_bulk_load_tag_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"bulk_load_tags"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/agreements/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_corporate_agreement_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"agreements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/agreement_documents/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_corporate_agreement_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"agreement_documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/bulk/request/zip/documents/:id/download/:attachment_id(.:format)
 * @param {any} id
 * @param {any} attachment_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_corporate_bulk_request_zip_document_path = __jsr.r({"id":{"r":true},"attachment_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"request"],[2,[7,"/"],[2,[6,"zip"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[2,[7,"/"],[2,[3,"attachment_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer_onboarding_processes/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_customer_onboarding_process_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer_onboarding_processes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/entity_folder/check_list_shareds/:id/task/:task_id/download_document(.:format)
 * @param {any} id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_document_shared_entity_folder_check_list_shared_path = __jsr.r({"id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"entity_folder"],[2,[7,"/"],[2,[6,"check_list_shareds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"task"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"download_document"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/bulk/load/entities/download_example(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_example_abstract_bulk_load_entities_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{"d":"xlsx"}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"load"],[2,[7,"/"],[2,[6,"entities"],[2,[7,"/"],[2,[6,"download_example"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /bulk_load_tags/download_example(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_example_bulk_load_tags_path = __jsr.r({"format":{"d":"xlsx"}}, [2,[7,"/"],[2,[6,"bulk_load_tags"],[2,[7,"/"],[2,[6,"download_example"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /dt/bulk/load/contract_records/download_example(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_example_dt_bulk_load_contract_records_path = __jsr.r({"format":{"d":"xlsx"}}, [2,[7,"/"],[2,[6,"dt"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"load"],[2,[7,"/"],[2,[6,"contract_records"],[2,[7,"/"],[2,[6,"download_example"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/bulk/load/employees/download_example(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_example_laboral_bulk_load_employees_path = __jsr.r({"format":{"d":"xlsx"}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"load"],[2,[7,"/"],[2,[6,"employees"],[2,[7,"/"],[2,[6,"download_example"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/bulk/load/hirings/download_example(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_example_laboral_bulk_load_hirings_path = __jsr.r({"format":{"d":"xlsx"}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"load"],[2,[7,"/"],[2,[6,"hirings"],[2,[7,"/"],[2,[6,"download_example"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/entity_folder/check_list_tasks/:id/download_file(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_file_abstract_entity_folder_check_list_task_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"entity_folder"],[2,[7,"/"],[2,[6,"check_list_tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download_file"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/entity_folder/check_list_shareds/:id/task/:task_id/download_file(.:format)
 * @param {any} id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_file_shared_entity_folder_check_list_shared_path = __jsr.r({"id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"entity_folder"],[2,[7,"/"],[2,[6,"check_list_shareds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"task"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"download_file"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/entity_folder/check_list_tasks/:id/download_file(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_file_shared_entity_folder_check_list_task_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"entity_folder"],[2,[7,"/"],[2,[6,"check_list_tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download_file"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/:tenant/workflow/requirement_stages/:id/download/:file_item_id/input/file_item(.:format)
 * @param {any} tenant
 * @param {any} id
 * @param {any} file_item_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_input_file_item_portal_workflow_requirement_stage_path = __jsr.r({"tenant":{"r":true},"id":{"r":true},"file_item_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"requirement_stages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[2,[7,"/"],[2,[3,"file_item_id"],[2,[7,"/"],[2,[6,"input"],[2,[7,"/"],[2,[6,"file_item"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/abstract/workflow/requirements/:customer_id/:process_id/:id/:requirement_stage_id/download/:file_item_id/input/file_item(.:format)
 * @param {any} customer_id
 * @param {any} process_id
 * @param {any} id
 * @param {any} requirement_stage_id
 * @param {any} file_item_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_input_file_item_public_abstract_workflow_requirement_path = __jsr.r({"customer_id":{"r":true},"process_id":{"r":true},"id":{"r":true},"requirement_stage_id":{"r":true},"file_item_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"abstract"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"requirements"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[3,"process_id"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[3,"requirement_stage_id"],[2,[7,"/"],[2,[6,"download"],[2,[7,"/"],[2,[3,"file_item_id"],[2,[7,"/"],[2,[6,"input"],[2,[7,"/"],[2,[6,"file_item"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/ley_karin/denuncia/:customer_id/:process_id/:id/:requirement_stage_id/download/:file_item_id/input/file_item(.:format)
 * @param {any} customer_id
 * @param {any} process_id
 * @param {any} id
 * @param {any} requirement_stage_id
 * @param {any} file_item_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_input_file_item_public_karin_law_workflow_requirement_path = __jsr.r({"customer_id":{"r":true},"process_id":{"r":true},"id":{"r":true},"requirement_stage_id":{"r":true},"file_item_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"ley_karin"],[2,[7,"/"],[2,[6,"denuncia"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[3,"process_id"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[3,"requirement_stage_id"],[2,[7,"/"],[2,[6,"download"],[2,[7,"/"],[2,[3,"file_item_id"],[2,[7,"/"],[2,[6,"input"],[2,[7,"/"],[2,[6,"file_item"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/bulk/load/employees/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_laboral_bulk_load_employee_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"load"],[2,[7,"/"],[2,[6,"employees"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/bulk/load/hirings/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_laboral_bulk_load_hiring_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"load"],[2,[7,"/"],[2,[6,"hirings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/bulk/request/zip/documents/:id/download/:attachment_id(.:format)
 * @param {any} id
 * @param {any} attachment_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_laboral_bulk_request_zip_document_path = __jsr.r({"id":{"r":true},"attachment_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"request"],[2,[7,"/"],[2,[6,"zip"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[2,[7,"/"],[2,[3,"attachment_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/hirings/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_laboral_hiring_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"hirings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/hiring_documents/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_laboral_hiring_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"hiring_documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/workflow/requirement_stages/:id/download/:file_item_id/output/file_item(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {any} file_item_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_output_file_item_abstract_workflow_requirement_stage_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"file_item_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"requirement_stages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[2,[7,"/"],[2,[3,"file_item_id"],[2,[7,"/"],[2,[6,"output"],[2,[7,"/"],[2,[6,"file_item"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /karin_law/workflow/requirement_stages/:id/download/:file_item_id/output/file_item(.:format)
 * @param {any} id
 * @param {any} file_item_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_output_file_item_karin_law_workflow_requirement_stage_path = __jsr.r({"id":{"r":true},"file_item_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"karin_law"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"requirement_stages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[2,[7,"/"],[2,[3,"file_item_id"],[2,[7,"/"],[2,[6,"output"],[2,[7,"/"],[2,[6,"file_item"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/:tenant/workflow/requirement_stages/:id/download/:file_item_id/output/file_item(.:format)
 * @param {any} tenant
 * @param {any} id
 * @param {any} file_item_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_output_file_item_portal_workflow_requirement_stage_path = __jsr.r({"tenant":{"r":true},"id":{"r":true},"file_item_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"requirement_stages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[2,[7,"/"],[2,[3,"file_item_id"],[2,[7,"/"],[2,[6,"output"],[2,[7,"/"],[2,[6,"file_item"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/abstract/workflow/requirements/:customer_id/:process_id/:id/:requirement_stage_id/download/:file_item_id/output/file_item(.:format)
 * @param {any} customer_id
 * @param {any} process_id
 * @param {any} id
 * @param {any} requirement_stage_id
 * @param {any} file_item_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_output_file_item_public_abstract_workflow_requirement_path = __jsr.r({"customer_id":{"r":true},"process_id":{"r":true},"id":{"r":true},"requirement_stage_id":{"r":true},"file_item_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"abstract"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"requirements"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[3,"process_id"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[3,"requirement_stage_id"],[2,[7,"/"],[2,[6,"download"],[2,[7,"/"],[2,[3,"file_item_id"],[2,[7,"/"],[2,[6,"output"],[2,[7,"/"],[2,[6,"file_item"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/ley_karin/denuncia/:customer_id/:process_id/:id/:requirement_stage_id/download/:file_item_id/output/file_item(.:format)
 * @param {any} customer_id
 * @param {any} process_id
 * @param {any} id
 * @param {any} requirement_stage_id
 * @param {any} file_item_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_output_file_item_public_karin_law_workflow_requirement_path = __jsr.r({"customer_id":{"r":true},"process_id":{"r":true},"id":{"r":true},"requirement_stage_id":{"r":true},"file_item_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"ley_karin"],[2,[7,"/"],[2,[6,"denuncia"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[3,"process_id"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[3,"requirement_stage_id"],[2,[7,"/"],[2,[6,"download"],[2,[7,"/"],[2,[3,"file_item_id"],[2,[7,"/"],[2,[6,"output"],[2,[7,"/"],[2,[6,"file_item"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /portal_access/documents/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_portal_access_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"portal_access"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/:tenant/documents/:id/download(.:format)
 * @param {any} tenant
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_portal_document_path = __jsr.r({"tenant":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/:tenant/sign_documents/:id/download(.:format)
 * @param {any} tenant
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_portal_sign_document_path = __jsr.r({"tenant":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"sign_documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /totem/documents/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_totem_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"totem"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /portal_access/documents/download_zip(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_zip_portal_access_documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"portal_access"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"download_zip"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/workflow/processes/:id/draft(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const draft_abstract_workflow_process_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"processes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"draft"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /karin_law/workflow/processes/:id/draft(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const draft_karin_law_workflow_process_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"karin_law"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"processes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"draft"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /dt/bulk/load/contract_records/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dt_bulk_load_contract_record_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dt"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"load"],[2,[7,"/"],[2,[6,"contract_records"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /dt/bulk/load/contract_records(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dt_bulk_load_contract_records_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"dt"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"load"],[2,[7,"/"],[2,[6,"contract_records"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /ecert/signature_certificates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecert_signature_certificate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ecert"],[2,[7,"/"],[2,[6,"signature_certificates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ecert/signature_certificates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ecert_signature_certificates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ecert"],[2,[7,"/"],[2,[6,"signature_certificates"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/bulk/load/documents/:id/edit(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_abstract_bulk_load_document_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"load"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/bulk/load/entities/:id/edit(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_abstract_bulk_load_entity_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"load"],[2,[7,"/"],[2,[6,"entities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/bulk/request/zip/documents/:id/edit(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_abstract_bulk_request_zip_document_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"request"],[2,[7,"/"],[2,[6,"zip"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/check_list/processes/:id/edit(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_abstract_check_list_process_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"check_list"],[2,[7,"/"],[2,[6,"processes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/check_list/tasks/:id/edit(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_abstract_check_list_task_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"check_list"],[2,[7,"/"],[2,[6,"tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/controldoc_form/templates/:id/edit(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_abstract_controldoc_form_template_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/controldoc_form/template_layouts/:id/edit(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_abstract_controldoc_form_template_layout_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"template_layouts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/controldoc_sign/document/meta_informations/:id/edit(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_abstract_controldoc_sign_document_meta_information_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"controldoc_sign"],[2,[7,"/"],[2,[6,"document"],[2,[7,"/"],[2,[6,"meta_informations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/custom_fields/:id/edit(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_abstract_custom_field_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"custom_fields"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/documents/:id/edit(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_abstract_document_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/document_types/:id/edit(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_abstract_document_type_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"document_types"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/document_type_batteries/:id/edit(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_abstract_document_type_battery_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"document_type_batteries"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/entities/:id/edit(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_abstract_entity_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"entities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/entity_folder/check_lists/:id/edit(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_abstract_entity_folder_check_list_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"entity_folder"],[2,[7,"/"],[2,[6,"check_lists"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/entity_folder/check_list_shareds/:id/edit(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_abstract_entity_folder_check_list_shared_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"entity_folder"],[2,[7,"/"],[2,[6,"check_list_shareds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/entity_folder/check_list_tasks/:id/edit(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_abstract_entity_folder_check_list_task_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"entity_folder"],[2,[7,"/"],[2,[6,"check_list_tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/fao/mapping_configs/:id/edit(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_abstract_fao_mapping_config_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"fao"],[2,[7,"/"],[2,[6,"mapping_configs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/workflow/processes/:id/edit(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_abstract_workflow_process_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"processes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/workflow/requirement_stages/:id/edit(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_abstract_workflow_requirement_stage_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"requirement_stages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/abstract_entity_types/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_abstract_entity_type_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"abstract_entity_types"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/abstract_entity_type_configs/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_abstract_entity_type_config_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"abstract_entity_type_configs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/admin_users/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"admin_users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/blog_posts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_blog_post_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"blog_posts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/campaign_referral_users/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_campaign_referral_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"campaign_referral_users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/change_logs/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_change_log_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"change_logs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/crm_post_sale_interviews/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_crm_post_sale_interview_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"crm_post_sale_interviews"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/crm_post_sale_tasks/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_crm_post_sale_task_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"crm_post_sale_tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/crm_pre_sale_interviews/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_crm_pre_sale_interview_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"crm_pre_sale_interviews"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/crm_pre_sale_tasks/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_crm_pre_sale_task_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"crm_pre_sale_tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/crm_task_reports/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_crm_task_report_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"crm_task_reports"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/crm_tech_tasks/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_crm_tech_task_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"crm_tech_tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/customers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_customer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/customer_onboarding_processes/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_customer_onboarding_process_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"customer_onboarding_processes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/customer_service_suspensions/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_customer_service_suspension_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"customer_service_suspensions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/nps_records/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_nps_record_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"nps_records"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/nps_results/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_nps_result_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"nps_results"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/page_documentations/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_page_documentation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"page_documentations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/products/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_product_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/roles/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_role_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"roles"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/system_notifications/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_system_notification_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"system_notifications"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_admin_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /billing/accounts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_billing_account_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"billing"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /bulk_load_tags/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_bulk_load_tag_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"bulk_load_tags"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/agreements/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_corporate_agreement_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"agreements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/agreement_compliance_processes/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_corporate_agreement_compliance_process_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"agreement_compliance_processes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/agreement_compliance_requirements/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_corporate_agreement_compliance_requirement_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"agreement_compliance_requirements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/agreement_documents/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_corporate_agreement_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"agreement_documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/bulk/notification/signs/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_corporate_bulk_notification_sign_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"notification"],[2,[7,"/"],[2,[6,"signs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/bulk/request/zip/documents/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_corporate_bulk_request_zip_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"request"],[2,[7,"/"],[2,[6,"zip"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/check_list/agreement_processes/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_corporate_check_list_agreement_process_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"check_list"],[2,[7,"/"],[2,[6,"agreement_processes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/check_list/agreement_tasks/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_corporate_check_list_agreement_task_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"check_list"],[2,[7,"/"],[2,[6,"agreement_tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/companies/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_corporate_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/controldoc_form/templates/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_corporate_controldoc_form_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/controldoc_form/template_layouts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_corporate_controldoc_form_template_layout_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"template_layouts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/custom_fields/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_corporate_custom_field_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"custom_fields"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/document_types/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_corporate_document_type_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"document_types"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/document_type_batteries/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_corporate_document_type_battery_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"document_type_batteries"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /orgs/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_customer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"orgs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /default_signers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_default_signer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"default_signers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ecert/signature_certificates/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_ecert_signature_certificate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"ecert"],[2,[7,"/"],[2,[6,"signature_certificates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /groups/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_group_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"groups"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /karin_law/complaints/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_karin_law_complaint_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"karin_law"],[2,[7,"/"],[2,[6,"complaints"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /karin_law/custom_fields/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_karin_law_custom_field_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"karin_law"],[2,[7,"/"],[2,[6,"custom_fields"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /karin_law/document_types/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_karin_law_document_type_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"karin_law"],[2,[7,"/"],[2,[6,"document_types"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /karin_law/workflow/processes/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_karin_law_workflow_process_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"karin_law"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"processes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/bulk/load/employees/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_laboral_bulk_load_employee_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"load"],[2,[7,"/"],[2,[6,"employees"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/bulk/load/hirings/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_laboral_bulk_load_hiring_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"load"],[2,[7,"/"],[2,[6,"hirings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/bulk/load/hiring_documents/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_laboral_bulk_load_hiring_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"load"],[2,[7,"/"],[2,[6,"hiring_documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/bulk/my_filters/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_laboral_bulk_my_filter_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"my_filters"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/bulk/request/zip/documents/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_laboral_bulk_request_zip_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"request"],[2,[7,"/"],[2,[6,"zip"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/check_list/hiring_processes/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_laboral_check_list_hiring_process_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"check_list"],[2,[7,"/"],[2,[6,"hiring_processes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/check_list/hiring_tasks/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_laboral_check_list_hiring_task_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"check_list"],[2,[7,"/"],[2,[6,"hiring_tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/controldoc_form/templates/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_laboral_controldoc_form_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/controldoc_form/template_layouts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_laboral_controldoc_form_template_layout_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"template_layouts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/controldoc_sign/document/meta_informations/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_laboral_controldoc_sign_document_meta_information_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"controldoc_sign"],[2,[7,"/"],[2,[6,"document"],[2,[7,"/"],[2,[6,"meta_informations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/custom_fields/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_laboral_custom_field_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"custom_fields"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/document_types/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_laboral_document_type_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"document_types"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/document_type_batteries/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_laboral_document_type_battery_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"document_type_batteries"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/employees/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_laboral_employee_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"employees"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/hirings/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_laboral_hiring_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"hirings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/hiring_compliance_processes/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_laboral_hiring_compliance_process_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"hiring_compliance_processes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/hiring_compliance_requirements/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_laboral_hiring_compliance_requirement_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"hiring_compliance_requirements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/hiring_documents/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_laboral_hiring_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"hiring_documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/shortlist/mapping_configs/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_laboral_shortlist_mapping_configs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"shortlist"],[2,[7,"/"],[2,[6,"mapping_configs"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/signers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_laboral_signer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"signers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /memberships/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_membership_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"memberships"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /portal_access/check_list/shared_processes/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_portal_access_check_list_shared_process_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"portal_access"],[2,[7,"/"],[2,[6,"check_list"],[2,[7,"/"],[2,[6,"shared_processes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /portal/configurations/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_portal_configurations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"portal"],[2,[7,"/"],[2,[6,"configurations"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /profiles/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_profiles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /security_layers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_security_layer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"security_layers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /signers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_signer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"signers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /tags/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_tag_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tags"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /tag_categories/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_tag_category_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tag_categories"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /elige-controldoc(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const elige_controldoc_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"elige-controldoc"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /faq(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const faq_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"faq"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /faq-est(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const faq_est_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"faq-est"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /firma-tus-documentos(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const firma_tus_documentos_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"firma-tus-documentos"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /forms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const forms_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"forms"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/documents/get_by_ids(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_by_ids_abstract_documents_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"get_by_ids"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/agreement_documents/get_by_ids(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_by_ids_corporate_agreement_documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"agreement_documents"],[2,[7,"/"],[2,[6,"get_by_ids"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/hiring_documents/get_by_ids(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_by_ids_laboral_hiring_documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"hiring_documents"],[2,[7,"/"],[2,[6,"get_by_ids"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /gracias(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const gracias_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"gracias"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /groups/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const group_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"groups"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /groups(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const groups_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"groups"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const info_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /inmobiliaria(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const inmobiliaria_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"inmobiliaria"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /inscripcion(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const inscripcion_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"inscripcion"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /inscripcion-evento(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const inscripcion_evento_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"inscripcion-evento"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id/invite(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invite_admin_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"invite"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /karin_law/complaints/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const karin_law_complaint_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"karin_law"],[2,[7,"/"],[2,[6,"complaints"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /karin_law/complaints(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const karin_law_complaints_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"karin_law"],[2,[7,"/"],[2,[6,"complaints"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /karin_law/custom_fields/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const karin_law_custom_field_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"karin_law"],[2,[7,"/"],[2,[6,"custom_fields"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /karin_law/custom_fields(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const karin_law_custom_fields_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"karin_law"],[2,[7,"/"],[2,[6,"custom_fields"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /karin_law/document_types/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const karin_law_document_type_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"karin_law"],[2,[7,"/"],[2,[6,"document_types"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /karin_law/document_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const karin_law_document_types_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"karin_law"],[2,[7,"/"],[2,[6,"document_types"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /karin_law/workflow/edges/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const karin_law_workflow_edge_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"karin_law"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"edges"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /karin_law/workflow/edges(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const karin_law_workflow_edges_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"karin_law"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"edges"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /karin_law/workflow/groups(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const karin_law_workflow_groups_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"karin_law"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"groups"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /karin_law/workflow/processes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const karin_law_workflow_process_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"karin_law"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"processes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /karin_law/workflow/processes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const karin_law_workflow_processes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"karin_law"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"processes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /karin_law/workflow/requirements/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const karin_law_workflow_requirement_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"karin_law"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"requirements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /karin_law/workflow/requirement_stages/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const karin_law_workflow_requirement_stage_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"karin_law"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"requirement_stages"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /karin_law/workflow/requirements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const karin_law_workflow_requirements_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"karin_law"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"requirements"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /karin_law/workflow/stages/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const karin_law_workflow_stage_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"karin_law"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"stages"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /karin_law/workflow/stages(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const karin_law_workflow_stages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"karin_law"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"stages"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /laboral/bulk/load/employees/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_bulk_load_employee_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"load"],[2,[7,"/"],[2,[6,"employees"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/bulk/load/employees(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_bulk_load_employees_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"load"],[2,[7,"/"],[2,[6,"employees"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/bulk/load/hirings/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_bulk_load_hiring_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"load"],[2,[7,"/"],[2,[6,"hirings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/bulk/load/hiring_documents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_bulk_load_hiring_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"load"],[2,[7,"/"],[2,[6,"hiring_documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/bulk/load/hiring_documents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_bulk_load_hiring_documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"load"],[2,[7,"/"],[2,[6,"hiring_documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/bulk/load/hirings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_bulk_load_hirings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"load"],[2,[7,"/"],[2,[6,"hirings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/bulk/my_filters/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_bulk_my_filter_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"my_filters"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/bulk/my_filters(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_bulk_my_filters_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"my_filters"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/bulk/notification/signs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_bulk_notification_sign_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"notification"],[2,[7,"/"],[2,[6,"signs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/bulk/notification/sign_items/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_bulk_notification_sign_item_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"notification"],[2,[7,"/"],[2,[6,"sign_items"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/bulk/notification/signs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_bulk_notification_signs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"notification"],[2,[7,"/"],[2,[6,"signs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/bulk/request/destroy_documents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_bulk_request_destroy_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"request"],[2,[7,"/"],[2,[6,"destroy_documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/bulk/request/destroy_documents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_bulk_request_destroy_documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"request"],[2,[7,"/"],[2,[6,"destroy_documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/bulk/request/destroy_employees/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_bulk_request_destroy_employee_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"request"],[2,[7,"/"],[2,[6,"destroy_employees"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/bulk/request/destroy_employee_items/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_bulk_request_destroy_employee_item_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"request"],[2,[7,"/"],[2,[6,"destroy_employee_items"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/bulk/request/destroy_employees(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_bulk_request_destroy_employees_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"request"],[2,[7,"/"],[2,[6,"destroy_employees"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/bulk/request/hiring_documents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_bulk_request_hiring_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"request"],[2,[7,"/"],[2,[6,"hiring_documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/bulk/request/hiring_documents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_bulk_request_hiring_documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"request"],[2,[7,"/"],[2,[6,"hiring_documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/bulk/request/hiring_items/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_bulk_request_hiring_item_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"request"],[2,[7,"/"],[2,[6,"hiring_items"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/bulk/request/zip/documents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_bulk_request_zip_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"request"],[2,[7,"/"],[2,[6,"zip"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/bulk/request/zip/documents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_bulk_request_zip_documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"request"],[2,[7,"/"],[2,[6,"zip"],[2,[7,"/"],[2,[6,"documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/check_list/hiring_processes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_check_list_hiring_process_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"check_list"],[2,[7,"/"],[2,[6,"hiring_processes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/check_list/hiring_processes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_check_list_hiring_processes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"check_list"],[2,[7,"/"],[2,[6,"hiring_processes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/check_list/hiring_tasks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_check_list_hiring_task_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"check_list"],[2,[7,"/"],[2,[6,"hiring_tasks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/check_list/hiring_tasks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_check_list_hiring_tasks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"check_list"],[2,[7,"/"],[2,[6,"hiring_tasks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/hirings/:id/controldoc_consent_annex/hiring_documents(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_controldoc_consent_annex_hiring_documents_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"hirings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"controldoc_consent_annex"],[2,[7,"/"],[2,[6,"hiring_documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/controldoc_form/documents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_controldoc_form_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/controldoc_form/documents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_controldoc_form_documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/controldoc_form/templates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_controldoc_form_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"templates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/controldoc_form/template_layouts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_controldoc_form_template_layout_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"template_layouts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/controldoc_form/template_layouts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_controldoc_form_template_layouts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"template_layouts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/controldoc_form/templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_controldoc_form_templates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"templates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/controldoc_sign/document/meta_informations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_controldoc_sign_document_meta_information_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"controldoc_sign"],[2,[7,"/"],[2,[6,"document"],[2,[7,"/"],[2,[6,"meta_informations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/controldoc_sign/document/meta_informations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_controldoc_sign_document_meta_informations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"controldoc_sign"],[2,[7,"/"],[2,[6,"document"],[2,[7,"/"],[2,[6,"meta_informations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/custom_fields/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_custom_field_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"custom_fields"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/custom_fields(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_custom_fields_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"custom_fields"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /laboral/document_types/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_document_type_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"document_types"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/document_type_batteries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_document_type_batteries_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"document_type_batteries"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /laboral/document_type_batteries/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_document_type_battery_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"document_type_batteries"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/document_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_document_types_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"document_types"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /laboral/employees/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_employee_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"employees"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/employees(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_employees_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"employees"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /laboral/hirings/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_hiring_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"hirings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/hiring_compliance_processes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_hiring_compliance_process_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"hiring_compliance_processes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/hiring_compliance_processes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_hiring_compliance_processes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"hiring_compliance_processes"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /laboral/hiring_compliance_requirements/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_hiring_compliance_requirement_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"hiring_compliance_requirements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/hiring_compliance_requirements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_hiring_compliance_requirements_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"hiring_compliance_requirements"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /laboral/hiring_documents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_hiring_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"hiring_documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/hiring_documents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_hiring_documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"hiring_documents"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /laboral/hirings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_hirings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"hirings"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /laboral/shortlist/mapping_configs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_shortlist_mapping_configs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"shortlist"],[2,[7,"/"],[2,[6,"mapping_configs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/signers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_signer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"signers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/signers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const laboral_signers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"signers"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/controldoc_form/templates/:id/mapping_links(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mapping_links_abstract_controldoc_form_template_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"mapping_links"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/controldoc_form/templates/:id/mapping_links(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mapping_links_corporate_controldoc_form_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"mapping_links"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/controldoc_form/templates/:id/mapping_links(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mapping_links_laboral_controldoc_form_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"mapping_links"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /memberships/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const membership_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"memberships"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /memberships(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const memberships_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"memberships"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/bulk/load/documents/new(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_abstract_bulk_load_document_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"load"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/bulk/load/entities/new(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_abstract_bulk_load_entity_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"load"],[2,[7,"/"],[2,[6,"entities"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/bulk/request/documents/new(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_abstract_bulk_request_document_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"request"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/bulk/request/zip/documents/new(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_abstract_bulk_request_zip_document_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"request"],[2,[7,"/"],[2,[6,"zip"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/check_list/processes/new(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_abstract_check_list_process_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"check_list"],[2,[7,"/"],[2,[6,"processes"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/controldoc_form/templates/new(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_abstract_controldoc_form_template_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"templates"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/controldoc_form/template_layouts/new(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_abstract_controldoc_form_template_layout_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"template_layouts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/controldoc_sign/document/meta_informations/new(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_abstract_controldoc_sign_document_meta_information_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"controldoc_sign"],[2,[7,"/"],[2,[6,"document"],[2,[7,"/"],[2,[6,"meta_informations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/custom_fields/new(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_abstract_custom_field_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"custom_fields"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/documents/new(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_abstract_document_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/document_types/new(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_abstract_document_type_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"document_types"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/document_type_batteries/new(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_abstract_document_type_battery_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"document_type_batteries"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/entities/new(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_abstract_entity_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"entities"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/entity_folder/check_lists/new(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_abstract_entity_folder_check_list_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"entity_folder"],[2,[7,"/"],[2,[6,"check_lists"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/entity_folder/check_list_requirements/new(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_abstract_entity_folder_check_list_requirement_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"entity_folder"],[2,[7,"/"],[2,[6,"check_list_requirements"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/entity_folder/check_list_shareds/new(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_abstract_entity_folder_check_list_shared_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"entity_folder"],[2,[7,"/"],[2,[6,"check_list_shareds"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/fao/mapping_configs/new(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_abstract_fao_mapping_config_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"fao"],[2,[7,"/"],[2,[6,"mapping_configs"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/report/documents/new(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_abstract_report_document_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/report/signers/new(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_abstract_report_signer_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"signers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/workflow/processes/new(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_abstract_workflow_process_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"processes"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/workflow/requirements/new(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_abstract_workflow_requirement_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"requirements"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/abstract_entity_types/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_abstract_entity_type_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"abstract_entity_types"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/abstract_entity_type_configs/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_abstract_entity_type_config_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"abstract_entity_type_configs"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/admin_users/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_admin_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"admin_users"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/blog_posts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_blog_post_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"blog_posts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/change_logs/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_change_log_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"change_logs"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/crm_post_sale_interviews/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_crm_post_sale_interview_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"crm_post_sale_interviews"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/crm_post_sale_tasks/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_crm_post_sale_task_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"crm_post_sale_tasks"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/crm_pre_sale_interviews/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_crm_pre_sale_interview_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"crm_pre_sale_interviews"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/crm_pre_sale_tasks/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_crm_pre_sale_task_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"crm_pre_sale_tasks"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/crm_task_reports/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_crm_task_report_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"crm_task_reports"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/crm_tech_tasks/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_crm_tech_task_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"crm_tech_tasks"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/customers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_customer_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/customer_onboarding_processes/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_customer_onboarding_process_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"customer_onboarding_processes"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/customer_service_suspensions/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_customer_service_suspension_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"customer_service_suspensions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/nps_records/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_nps_record_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"nps_records"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/nps_results/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_nps_result_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"nps_results"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/page_documentations/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_page_documentation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"page_documentations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/products/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_product_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/roles/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_role_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"roles"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/system_notifications/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_system_notification_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"system_notifications"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/login(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_admin_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"login"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /bulk_load_tags/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_bulk_load_tag_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"bulk_load_tags"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /campaign/referral_users/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_campaign_referral_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"campaign"],[2,[7,"/"],[2,[6,"referral_users"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/agreements/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_corporate_agreement_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"agreements"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/agreement_compliance_processes/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_corporate_agreement_compliance_process_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"agreement_compliance_processes"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/agreement_compliance_requirements/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_corporate_agreement_compliance_requirement_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"agreement_compliance_requirements"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/agreement_documents/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_corporate_agreement_document_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"agreement_documents"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/bulk/notification/signs/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_corporate_bulk_notification_sign_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"notification"],[2,[7,"/"],[2,[6,"signs"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/bulk/request/agreement_documents/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_corporate_bulk_request_agreement_document_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"request"],[2,[7,"/"],[2,[6,"agreement_documents"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/bulk/request/zip/documents/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_corporate_bulk_request_zip_document_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"request"],[2,[7,"/"],[2,[6,"zip"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/check_list/agreement_processes/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_corporate_check_list_agreement_process_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"check_list"],[2,[7,"/"],[2,[6,"agreement_processes"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/companies/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_corporate_company_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/controldoc_form/templates/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_corporate_controldoc_form_template_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"templates"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/controldoc_form/template_layouts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_corporate_controldoc_form_template_layout_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"template_layouts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/custom_fields/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_corporate_custom_field_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"custom_fields"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/document_types/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_corporate_document_type_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"document_types"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/document_type_batteries/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_corporate_document_type_battery_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"document_type_batteries"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /default_signers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_default_signer_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"default_signers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /dt/bulk/load/contract_records/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_dt_bulk_load_contract_record_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"dt"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"load"],[2,[7,"/"],[2,[6,"contract_records"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /ecert/signature_certificates/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_ecert_signature_certificate_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ecert"],[2,[7,"/"],[2,[6,"signature_certificates"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /groups/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_group_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"groups"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /karin_law/complaints/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_karin_law_complaint_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"karin_law"],[2,[7,"/"],[2,[6,"complaints"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /karin_law/custom_fields/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_karin_law_custom_field_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"karin_law"],[2,[7,"/"],[2,[6,"custom_fields"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /karin_law/document_types/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_karin_law_document_type_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"karin_law"],[2,[7,"/"],[2,[6,"document_types"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /karin_law/workflow/processes/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_karin_law_workflow_process_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"karin_law"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"processes"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /karin_law/workflow/requirements/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_karin_law_workflow_requirement_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"karin_law"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"requirements"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/bulk/load/employees/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_laboral_bulk_load_employee_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"load"],[2,[7,"/"],[2,[6,"employees"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/bulk/load/hirings/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_laboral_bulk_load_hiring_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"load"],[2,[7,"/"],[2,[6,"hirings"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/bulk/load/hiring_documents/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_laboral_bulk_load_hiring_document_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"load"],[2,[7,"/"],[2,[6,"hiring_documents"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/bulk/my_filters/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_laboral_bulk_my_filter_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"my_filters"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/bulk/notification/signs/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_laboral_bulk_notification_sign_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"notification"],[2,[7,"/"],[2,[6,"signs"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/bulk/request/destroy_documents/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_laboral_bulk_request_destroy_document_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"request"],[2,[7,"/"],[2,[6,"destroy_documents"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/bulk/request/destroy_employees/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_laboral_bulk_request_destroy_employee_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"request"],[2,[7,"/"],[2,[6,"destroy_employees"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/bulk/request/hiring_documents/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_laboral_bulk_request_hiring_document_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"request"],[2,[7,"/"],[2,[6,"hiring_documents"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/bulk/request/zip/documents/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_laboral_bulk_request_zip_document_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"request"],[2,[7,"/"],[2,[6,"zip"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/check_list/hiring_processes/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_laboral_check_list_hiring_process_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"check_list"],[2,[7,"/"],[2,[6,"hiring_processes"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/hirings/:id/controldoc_consent_annex/hiring_documents/new(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_laboral_controldoc_consent_annex_hiring_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"hirings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"controldoc_consent_annex"],[2,[7,"/"],[2,[6,"hiring_documents"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/controldoc_form/templates/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_laboral_controldoc_form_template_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"templates"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/controldoc_form/template_layouts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_laboral_controldoc_form_template_layout_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"template_layouts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/controldoc_sign/document/meta_informations/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_laboral_controldoc_sign_document_meta_information_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"controldoc_sign"],[2,[7,"/"],[2,[6,"document"],[2,[7,"/"],[2,[6,"meta_informations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/custom_fields/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_laboral_custom_field_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"custom_fields"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/document_types/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_laboral_document_type_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"document_types"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/document_type_batteries/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_laboral_document_type_battery_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"document_type_batteries"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/employees/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_laboral_employee_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"employees"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/hirings/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_laboral_hiring_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"hirings"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/hiring_compliance_processes/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_laboral_hiring_compliance_process_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"hiring_compliance_processes"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/hiring_compliance_requirements/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_laboral_hiring_compliance_requirement_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"hiring_compliance_requirements"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/hiring_documents/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_laboral_hiring_document_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"hiring_documents"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/signers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_laboral_signer_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"signers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /memberships/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_membership_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"memberships"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /portal_access/check_list/shared_processes/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_portal_access_check_list_shared_process_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"portal_access"],[2,[7,"/"],[2,[6,"check_list"],[2,[7,"/"],[2,[6,"shared_processes"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/:tenant/sessions/new(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_portal_session_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"sessions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/abstract/workflow/requirements/:customer_id/:process_id/new(.:format)
 * @param {any} customer_id
 * @param {any} process_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_public_abstract_workflow_requirement_path = __jsr.r({"customer_id":{"r":true},"process_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"abstract"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"requirements"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[3,"process_id"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/ley_karin/denuncia/:customer_id/:process_id/new(.:format)
 * @param {any} customer_id
 * @param {any} process_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_public_karin_law_workflow_requirement_path = __jsr.r({"customer_id":{"r":true},"process_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"ley_karin"],[2,[7,"/"],[2,[6,"denuncia"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[3,"process_id"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_source_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /report/corporate/agreement_documents/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_report_corporate_agreement_document_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"agreement_documents"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /report/corporate/signers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_report_corporate_signer_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"signers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /report/laboral/hirings/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_report_laboral_hiring_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"hirings"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /report/laboral/hiring_documents/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_report_laboral_hiring_document_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"hiring_documents"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /report/laboral/signers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_report_laboral_signer_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"signers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/entity_folder/check_list_shareds/:id/requirement/:folder_id(.:format)
 * @param {any} id
 * @param {any} folder_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_requirement_shared_entity_folder_check_list_shared_path = __jsr.r({"id":{"r":true},"folder_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"entity_folder"],[2,[7,"/"],[2,[6,"check_list_shareds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"requirement"],[2,[7,"/"],[2,[3,"folder_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /security_layers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_security_layer_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"security_layers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /signers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_signer_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /tags/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_tag_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tags"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /tag_categories/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_tag_category_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tag_categories"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /totem/sessions/:customer_id/new(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_totem_session_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"totem"],[2,[7,"/"],[2,[6,"sessions"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/invitation/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"invitation"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /customer_onboarding_processes/next_step(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const next_step_customer_onboarding_processes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer_onboarding_processes"],[2,[7,"/"],[2,[6,"next_step"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /normative(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const normative_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"normative"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/custom_fields/order(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const order_abstract_custom_fields_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"custom_fields"],[2,[7,"/"],[2,[6,"order"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/custom_fields/order(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const order_laboral_custom_fields_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"custom_fields"],[2,[7,"/"],[2,[6,"order"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer_onboarding_processes/:id/pin_form/:responsible(.:format)
 * @param {any} id
 * @param {any} responsible
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pin_form_customer_onboarding_process_path = __jsr.r({"id":{"r":true},"responsible":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer_onboarding_processes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"pin_form"],[2,[7,"/"],[2,[3,"responsible"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/:tenant(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portal_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[3,"tenant"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /portal_access/check_list/shared_processes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portal_access_check_list_shared_process_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"portal_access"],[2,[7,"/"],[2,[6,"check_list"],[2,[7,"/"],[2,[6,"shared_processes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /portal_access/check_list/shared_processes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portal_access_check_list_shared_processes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"portal_access"],[2,[7,"/"],[2,[6,"check_list"],[2,[7,"/"],[2,[6,"shared_processes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /portal_access/documents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portal_access_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"portal_access"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /portal_access/documents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portal_access_documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"portal_access"],[2,[7,"/"],[2,[6,"documents"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /p/:tenant/comment/workflow/requirements/:resource_id(.:format)
 * @param {any} tenant
 * @param {any} resource_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portal_comment_workflow_requirements_path = __jsr.r({"tenant":{"r":true},"resource_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"comment"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"requirements"],[2,[7,"/"],[2,[3,"resource_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /portal/configurations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portal_configurations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"portal"],[2,[7,"/"],[2,[6,"configurations"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /orgs/:id/portal(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portal_customer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"orgs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"portal"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /p/:tenant/dashboards(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portal_dashboards_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"dashboards"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /p/:tenant/documents(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portal_documents_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /p/:tenant/sessions(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portal_sessions_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"sessions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /p/:tenant/sign_documents(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portal_sign_documents_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"sign_documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /p/:tenant/workflow/requirements/:id(.:format)
 * @param {any} tenant
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portal_workflow_requirement_path = __jsr.r({"tenant":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"requirements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/:tenant/workflow/requirement_stages/:id(.:format)
 * @param {any} tenant
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portal_workflow_requirement_stage_path = __jsr.r({"tenant":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"requirement_stages"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/:tenant/workflow/requirements(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portal_workflow_requirements_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"requirements"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/controldoc_form/template_layouts/preview(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preview_abstract_controldoc_form_template_layouts_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{"d":"pdf"}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"template_layouts"],[2,[7,"/"],[2,[6,"preview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /customer_onboarding_processes/:id/preview_comercial_conditions(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preview_comercial_conditions_customer_onboarding_process_path = __jsr.r({"id":{"r":true},"format":{"d":"pdf"}}, [2,[7,"/"],[2,[6,"customer_onboarding_processes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"preview_comercial_conditions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /controldoc_form/images/:id/preview(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preview_controldoc_form_image_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"images"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"preview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/controldoc_form/template_layouts/preview(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preview_corporate_controldoc_form_template_layouts_path = __jsr.r({"format":{"d":"pdf"}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"template_layouts"],[2,[7,"/"],[2,[6,"preview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/check_list/hiring_tasks/preview(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preview_laboral_check_list_hiring_tasks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"check_list"],[2,[7,"/"],[2,[6,"hiring_tasks"],[2,[7,"/"],[2,[6,"preview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/controldoc_form/template_layouts/preview(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preview_laboral_controldoc_form_template_layouts_path = __jsr.r({"format":{"d":"pdf"}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"template_layouts"],[2,[7,"/"],[2,[6,"preview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/:tenant/documents/:id/preview(.:format)
 * @param {any} tenant
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preview_portal_document_path = __jsr.r({"tenant":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"preview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/:tenant/sign_documents/:id/preview(.:format)
 * @param {any} tenant
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preview_portal_sign_document_path = __jsr.r({"tenant":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"sign_documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"preview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /totem/documents/:id/preview(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preview_totem_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"totem"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"preview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /privacy_policy(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const privacy_policy_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"privacy_policy"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /profiles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const profiles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"profiles"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /public/abstract/workflow/requirements/:customer_id/:process_id/:id(.:format)
 * @param {any} customer_id
 * @param {any} process_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const public_abstract_workflow_requirement_path = __jsr.r({"customer_id":{"r":true},"process_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"abstract"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"requirements"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[3,"process_id"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/abstract/workflow/requirements/:customer_id/:process_id(.:format)
 * @param {any} customer_id
 * @param {any} process_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const public_abstract_workflow_requirements_path = __jsr.r({"customer_id":{"r":true},"process_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"abstract"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"requirements"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[3,"process_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/comment/workflow/requirements/:resource_id/:customer_id(.:format)
 * @param {any} resource_id
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const public_comment_workflow_requirements_path = __jsr.r({"resource_id":{"r":true},"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"comment"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"requirements"],[2,[7,"/"],[2,[3,"resource_id"],[2,[7,"/"],[2,[3,"customer_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/ley_karin/denuncia/:customer_id/:process_id/:id(.:format)
 * @param {any} customer_id
 * @param {any} process_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const public_karin_law_workflow_requirement_path = __jsr.r({"customer_id":{"r":true},"process_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"ley_karin"],[2,[7,"/"],[2,[6,"denuncia"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[3,"process_id"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /public/ley_karin/denuncia/:customer_id/:process_id(.:format)
 * @param {any} customer_id
 * @param {any} process_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const public_karin_law_workflow_requirements_path = __jsr.r({"customer_id":{"r":true},"process_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"public"],[2,[7,"/"],[2,[6,"ley_karin"],[2,[7,"/"],[2,[6,"denuncia"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[3,"process_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/workflow/processes/:id/publish(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publish_abstract_workflow_process_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"processes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"publish"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /karin_law/workflow/processes/:id/publish(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const publish_karin_law_workflow_process_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"karin_law"],[2,[7,"/"],[2,[6,"workflow"],[2,[7,"/"],[2,[6,"processes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"publish"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_proxy_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
 * @param {any} inbound_email_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_reroute_path = __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"reroute"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_sources_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/direct_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_direct_uploads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"direct_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_key/*filename(.:format)
 * @param {any} encoded_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_disk_service_path = __jsr.r({"encoded_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailgun_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mailgun"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"mime"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_health_check_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/performance
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_performance_path = __jsr.r({}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[6,"performance"]]]]);

/**
 * Generates rails route to
 * /rails/performance/
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_performance_rails_performance_path = __jsr.r({}, [2,[2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[6,"performance"]]]],[7,"/"]]);

/**
 * Generates rails route to
 * /rails/performance/requests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_performance_rails_performance_requests_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[6,"performance"]]]],[7,"/"]],[2,[6,"requests"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /rails/performance/crashes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_performance_rails_performance_crashes_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[6,"performance"]]]],[7,"/"]],[2,[6,"crashes"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /rails/performance/recent(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_performance_rails_performance_recent_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[6,"performance"]]]],[7,"/"]],[2,[6,"recent"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /rails/performance/trace/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_performance_rails_performance_trace_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[6,"performance"]]]],[7,"/"]],[2,[6,"trace"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/performance/summary(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_performance_rails_performance_summary_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[6,"performance"]]]],[7,"/"]],[2,[6,"summary"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /rails/performance/sidekiq(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_performance_rails_performance_sidekiq_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[6,"performance"]]]],[7,"/"]],[2,[6,"sidekiq"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /rails/performance/delayed_job(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_performance_rails_performance_delayed_job_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[6,"performance"]]]],[7,"/"]],[2,[6,"delayed_job"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /rails/performance/grape(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_performance_rails_performance_grape_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[6,"performance"]]]],[7,"/"]],[2,[6,"grape"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /rails/performance/rake(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_performance_rails_performance_rake_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[6,"performance"]]]],[7,"/"]],[2,[6,"rake"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /rails/performance/custom(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_performance_rails_performance_custom_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[6,"performance"]]]],[7,"/"]],[2,[6,"custom"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/postmark/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_postmark_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"postmark"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/relay/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_relay_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"relay"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/sendgrid/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_sendgrid_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"sendgrid"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_proxy_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/callbacks/controldoc_form/:controldoc_form_id/receive(.:format)
 * @param {any} controldoc_form_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const receive_api_v1_callbacks_controldoc_form_path = __jsr.r({"controldoc_form_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"callbacks"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[3,"controldoc_form_id"],[2,[7,"/"],[2,[6,"receive"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/controldoc/homes/receive_user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const receive_user_api_v1_controldoc_homes_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"controldoc"],[2,[7,"/"],[2,[6,"homes"],[2,[7,"/"],[2,[6,"receive_user"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /recursos/casos-de-exito(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const recursos_casos_de_exito_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"recursos"],[2,[7,"/"],[2,[6,"casos-de-exito"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /recursos/ebook(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const recursos_ebook_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"recursos"],[2,[7,"/"],[2,[6,"ebook"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /document_integration/controldoc_signs/:document_id/refresh(.:format)
 * @param {any} document_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const refresh_document_integration_controldoc_sign_path = __jsr.r({"document_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"document_integration"],[2,[7,"/"],[2,[6,"controldoc_signs"],[2,[7,"/"],[2,[3,"document_id"],[2,[7,"/"],[2,[6,"refresh"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/check_list/hiring_processes/:id/refresh(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const refresh_laboral_check_list_hiring_process_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"check_list"],[2,[7,"/"],[2,[6,"hiring_processes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"refresh"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/invitation/remove(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_user_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"invitation"],[2,[7,"/"],[2,[6,"remove"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /report/corporate/agreement_documents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const report_corporate_agreement_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"agreement_documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /report/corporate/agreement_documents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const report_corporate_agreement_documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"agreement_documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /report/corporate/signers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const report_corporate_signer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"signers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /report/corporate/signers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const report_corporate_signers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"signers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /report/laboral/hirings/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const report_laboral_hiring_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"hirings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /report/laboral/hiring_documents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const report_laboral_hiring_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"hiring_documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /report/laboral/hiring_documents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const report_laboral_hiring_documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"hiring_documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /report/laboral/hirings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const report_laboral_hirings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"hirings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /report/laboral/signers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const report_laboral_signer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"signers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /report/laboral/signers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const report_laboral_signers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"report"],[2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"signers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer_onboarding_processes/:id/request_pin(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const request_pin_customer_onboarding_process_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer_onboarding_processes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"request_pin"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /dt/bulk/load/contract_records/:id/request_status(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const request_status_dt_bulk_load_contract_record_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"dt"],[2,[7,"/"],[2,[6,"bulk"],[2,[7,"/"],[2,[6,"load"],[2,[7,"/"],[2,[6,"contract_records"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"request_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /nps/results/:id/respond(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const respond_nps_result_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"nps"],[2,[7,"/"],[2,[6,"results"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"respond"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const root_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /portal_access/documents/:id/save_upload(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const save_upload_portal_access_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"portal_access"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"save_upload"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/shortlist/assessment_load/callbacks/:id/screenings(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const screenings_api_v1_shortlist_assessment_load_callback_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"shortlist"],[2,[7,"/"],[2,[6,"assessment_load"],[2,[7,"/"],[2,[6,"callbacks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"screenings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /security_layers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const security_layer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"security_layers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /security_layers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const security_layers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"security_layers"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /signers/send_email_require_identity_verification(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_email_require_identity_verification_signers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signers"],[2,[7,"/"],[2,[6,"send_email_require_identity_verification"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /p/:tenant/sessions/send_pin_code_email(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_pin_code_email_portal_sessions_path = __jsr.r({"tenant":{"r":true},"format":{"d":"js"}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"sessions"],[2,[7,"/"],[2,[6,"send_pin_code_email"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /totem/sessions/:customer_id/send_pin_code_email(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_pin_code_email_totem_sessions_path = __jsr.r({"customer_id":{"r":true},"format":{"d":"js"}}, [2,[7,"/"],[2,[6,"totem"],[2,[7,"/"],[2,[6,"sessions"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"send_pin_code_email"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /p/:tenant/sessions/send_pin_code_sms(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_pin_code_sms_portal_sessions_path = __jsr.r({"tenant":{"r":true},"format":{"d":"js"}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"sessions"],[2,[7,"/"],[2,[6,"send_pin_code_sms"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /totem/sessions/:customer_id/send_pin_code_sms(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const send_pin_code_sms_totem_sessions_path = __jsr.r({"customer_id":{"r":true},"format":{"d":"js"}}, [2,[7,"/"],[2,[6,"totem"],[2,[7,"/"],[2,[6,"sessions"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"send_pin_code_sms"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/check_list/processes/:id/share(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const share_abstract_check_list_process_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"check_list"],[2,[7,"/"],[2,[6,"processes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"share"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /corporate/check_list/agreement_processes/:id/share(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const share_corporate_check_list_agreement_process_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"check_list"],[2,[7,"/"],[2,[6,"agreement_processes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"share"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/check_list/hiring_processes/:id/share(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const share_laboral_check_list_hiring_process_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"check_list"],[2,[7,"/"],[2,[6,"hiring_processes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"share"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/entity_folder/check_list_shareds/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shared_entity_folder_check_list_shared_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"entity_folder"],[2,[7,"/"],[2,[6,"check_list_shareds"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/entity_folder/check_list_tasks/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shared_entity_folder_check_list_task_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"entity_folder"],[2,[7,"/"],[2,[6,"check_list_tasks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /sidekiq
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sidekiq_web_path = __jsr.r({}, [2,[7,"/"],[6,"sidekiq"]]);

/**
 * Generates rails route to
 * /sign(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sign_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sign"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /signers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const signer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"signers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /signer_notifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const signer_notifications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signer_notifications"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /signers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const signers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signers"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /p/:tenant/sign_documents/success_sign(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const success_sign_portal_sign_documents_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"sign_documents"],[2,[7,"/"],[2,[6,"success_sign"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /totem/documents/success_sign(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const success_sign_totem_documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"totem"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[6,"success_sign"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /switch_user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const switch_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"switch_user"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /switch_user/remember_user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const switch_user_remember_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"switch_user"],[2,[7,"/"],[2,[6,"remember_user"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /corporate/controldoc_form/templates/sync(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sync_corporate_controldoc_form_templates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"corporate"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"templates"],[2,[7,"/"],[2,[6,"sync"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/controldoc_form/templates/sync(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sync_laboral_controldoc_form_templates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"controldoc_form"],[2,[7,"/"],[2,[6,"templates"],[2,[7,"/"],[2,[6,"sync"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /tags/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tag_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tags"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /tag_categories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tag_categories_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tag_categories"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /tag_categories/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tag_category_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tag_categories"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /tags(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tags_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tags"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /shared/entity_folder/check_list_shareds/:id/task/:task_id(.:format)
 * @param {any} id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const task_shared_entity_folder_check_list_shared_path = __jsr.r({"id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"entity_folder"],[2,[7,"/"],[2,[6,"check_list_shareds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"task"],[2,[7,"/"],[2,[3,"task_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /campaign/referral_users/terms_and_conditions_pdf(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const terms_and_conditions_pdf_campaign_referral_users_path = __jsr.r({"format":{"d":"pdf"}}, [2,[7,"/"],[2,[6,"campaign"],[2,[7,"/"],[2,[6,"referral_users"],[2,[7,"/"],[2,[6,"terms_and_conditions_pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer_onboarding_processes/terms_and_conditions_pdf(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const terms_and_conditions_pdf_customer_onboarding_processes_path = __jsr.r({"format":{"d":"pdf"}}, [2,[7,"/"],[2,[6,"customer_onboarding_processes"],[2,[7,"/"],[2,[6,"terms_and_conditions_pdf"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /thanks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const thanks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"thanks"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /totem/documents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const totem_documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"totem"],[2,[7,"/"],[2,[6,"documents"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /totem/sessions/:customer_id(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const totem_sessions_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"totem"],[2,[7,"/"],[2,[6,"sessions"],[2,[7,"/"],[2,[3,"customer_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /tutorial(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const tutorial_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"tutorial"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /p/:tenant/sessions/two_factor_authentication(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const two_factor_authentication_portal_sessions_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"sessions"],[2,[7,"/"],[2,[6,"two_factor_authentication"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /totem/sessions/:customer_id/two_factor_authentication(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const two_factor_authentication_totem_sessions_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"totem"],[2,[7,"/"],[2,[6,"sessions"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"two_factor_authentication"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/entity_folder/check_list_tasks/:id/update_file(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_file_abstract_entity_folder_check_list_task_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"entity_folder"],[2,[7,"/"],[2,[6,"check_list_tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_file"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/entity_folder/check_list_shareds/:id/task/:task_id/update_file(.:format)
 * @param {any} id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_file_shared_entity_folder_check_list_shared_path = __jsr.r({"id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"entity_folder"],[2,[7,"/"],[2,[6,"check_list_shareds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"task"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"update_file"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/entity_folder/check_list_tasks/:id/update_file(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_file_shared_entity_folder_check_list_task_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"entity_folder"],[2,[7,"/"],[2,[6,"check_list_tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_file"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/custom_fields/update_order(.:format)
 * @param {any} current_entity_type_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_order_abstract_custom_fields_path = __jsr.r({"current_entity_type_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"custom_fields"],[2,[7,"/"],[2,[6,"update_order"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /laboral/custom_fields/update_order(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_order_laboral_custom_fields_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"laboral"],[2,[7,"/"],[2,[6,"custom_fields"],[2,[7,"/"],[2,[6,"update_order"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_token(.:format)
 * @param {any} encoded_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_rails_disk_service_path = __jsr.r({"encoded_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /module/:current_entity_type_id/entity_folder/check_list_tasks/:id/upload_file(.:format)
 * @param {any} current_entity_type_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const upload_file_abstract_entity_folder_check_list_task_path = __jsr.r({"current_entity_type_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"module"],[2,[7,"/"],[2,[3,"current_entity_type_id"],[2,[7,"/"],[2,[6,"entity_folder"],[2,[7,"/"],[2,[6,"check_list_tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"upload_file"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/entity_folder/check_list_shareds/:id/task/:task_id/upload_file(.:format)
 * @param {any} id
 * @param {any} task_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const upload_file_shared_entity_folder_check_list_shared_path = __jsr.r({"id":{"r":true},"task_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"entity_folder"],[2,[7,"/"],[2,[6,"check_list_shareds"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"task"],[2,[7,"/"],[2,[3,"task_id"],[2,[7,"/"],[2,[6,"upload_file"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /shared/entity_folder/check_list_tasks/:id/upload_file(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const upload_file_shared_entity_folder_check_list_task_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"shared"],[2,[7,"/"],[2,[6,"entity_folder"],[2,[7,"/"],[2,[6,"check_list_tasks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"upload_file"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /portal_access/documents/:id/upload(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const upload_portal_access_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"portal_access"],[2,[7,"/"],[2,[6,"documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"upload"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/invitation(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_invitation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"invitation"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /customer_onboarding_processes/:id/validate_pin(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const validate_pin_customer_onboarding_process_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer_onboarding_processes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"validate_pin"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /p/:tenant/sessions/validate_user(.:format)
 * @param {any} tenant
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const validate_user_portal_sessions_path = __jsr.r({"tenant":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"p"],[2,[7,"/"],[2,[3,"tenant"],[2,[7,"/"],[2,[6,"sessions"],[2,[7,"/"],[2,[6,"validate_user"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /totem/sessions/:customer_id/validate_user(.:format)
 * @param {any} customer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const validate_user_totem_sessions_path = __jsr.r({"customer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"totem"],[2,[7,"/"],[2,[6,"sessions"],[2,[7,"/"],[2,[3,"customer_id"],[2,[7,"/"],[2,[6,"validate_user"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /welcome(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const welcome_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"welcome"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /customer_onboarding_processes/:id/welcome_completed(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const welcome_completed_customer_onboarding_process_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer_onboarding_processes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"welcome_completed"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customer_onboarding_processes/:id/welcome(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const welcome_customer_onboarding_process_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer_onboarding_processes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"welcome"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);


