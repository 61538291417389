import React, {
  useCallback,
  useMemo,
  useState,
  useContext,
  useEffect,
  useRef
} from "react";

import ReactFlow, {
  Background,
  Controls,
  useReactFlow,
  MiniMap,

  // Nodes
  useNodesState,
  applyNodeChanges,

  // Edges
  useEdgesState,
  addEdge,
  applyEdgeChanges,

} from 'reactflow';

import {
  Button
} from 'reactstrap';

import WorkflowDefaultNode from './common/custom_nodes/default_node';
import WorkflowInputNode from './common/custom_nodes/input_node';
import WorkflowOutputNode from './common/custom_nodes/output_node';
import WorkflowShortlistNode from './common/custom_nodes/shortlist_node';

import BidirecionalEdge from './common/custom_edges/bidireccional_edge';
import DirectionalEdge from './common/custom_edges/direccional_edge';

import WorkflowToolbar from './common/toolbar';

import WorkFlowNodeForm from "./common/node_form";

import { WorkflowDiagramProvider } from './diagram_context';

import {
  defaultEdgeOptions,
  defaultNodeOptions
} from './common/default_options'

import '../../styles/workflow_process'

const nodeTypes = {
  default: WorkflowDefaultNode,
  input: WorkflowInputNode,
  output: WorkflowOutputNode,
  shortlist: WorkflowShortlistNode
}

const edgeTypes = {
  bidirectional: BidirecionalEdge,
  directional: DirectionalEdge
}

const stageFormData = (formData, formName, node) => {
  formData.append(`${ formName }[id]`, node?.data?.id || '');
  formData.append(`${ formName }[name]`, node?.data?.label || '');
  formData.append(`${ formName }[description]`, node?.data?.description || '');
  formData.append(`${ formName }[days_duration]`, node?.data?.days_duration || 0);
  formData.append(`${ formName }[node_id]`, node?.id || '');
  formData.append(`${ formName }[config]`, JSON.stringify(node));

  if(node.type == 'input'){
    formData.append(`${ formName }[is_public]`, node?.data?.is_public || false);
    formData.append(`${ formName }[create_entity_on_approval]`, node?.data?.create_entity_on_approval || false);
  } else {
    formData.append(`${ formName }[is_public]`, false);
    formData.append(`${ formName }[create_entity_on_approval]`, false)
  }

  if(node.type == 'input' && node?.data?.create_entity_on_approval){
    _.each(node?.data?.entity_mapping_field, (itemFieldName, customFieldCode) => {
      formData.append(`${ formName }[entity_mapping_field][${ customFieldCode}]`, itemFieldName || '')
    })
  }

  // START SHORTLIST STAGE
  if(node.type == 'shortlist'){
    formData.append(`${ formName }[shortlist_project][id]`, node?.data?.shortlist_project?.id || '')
    formData.append(`${ formName }[shortlist_project][title]`, node?.data?.shortlist_project?.title || '')
    formData.append(`${ formName }[shortlist_project][url]`, node?.data?.shortlist_project?.url || '')
  }
  // END SHORTLIST STAGE

  // START INPUT STAGE FORM
  formData.append(`${ formName }[input_stage_form_attributes][id]`, node?.data?.input_stage_form?.id || '');

  if(node?.data?.input_stage_form?.field_items.length > 0){
    _.each(node.data.input_stage_form.field_items, (fieldItem, index) => {
      const fieldItemFormName = `${ formName }[input_stage_form_attributes][field_items_attributes]`;

      formData.append(`${ fieldItemFormName }[${ index }][id]`, fieldItem.id || '');
      formData.append(`${ fieldItemFormName }[${ index }][name]`, fieldItem.name || '');
      formData.append(`${ fieldItemFormName }[${ index }][data_type]`, fieldItem.data_type || '');
      formData.append(`${ fieldItemFormName }[${ index }][group_name]`, fieldItem.group_name || '');
      formData.append(`${ fieldItemFormName }[${ index }][helping_text]`, fieldItem.helping_text || '');
      formData.append(`${ fieldItemFormName }[${ index }][is_required]`, fieldItem.is_required || false);
      formData.append(`${ fieldItemFormName }[${ index }][_destroy]`, fieldItem._destroy || false);
    })
  }

  if(node?.data?.input_stage_form?.file_items.length > 0){
    _.each(node.data.input_stage_form.file_items, (fileItem, index) => {
      const fileItemFormName = `${ formName }[input_stage_form_attributes][file_items_attributes]`;

      formData.append(`${ fileItemFormName }[${ index }][id]`, fileItem.id || '');
      formData.append(`${ fileItemFormName }[${ index }][name]`, fileItem.name || '');
      formData.append(`${ fileItemFormName }[${ index }][is_required]`, fileItem.is_required || false);
      formData.append(`${ fileItemFormName }[${ index }][group_name]`, fileItem.group_name || '');
      formData.append(`${ fileItemFormName }[${ index }][helping_text]`, fileItem.helping_text || '');
      formData.append(`${ fileItemFormName }[${ index }][_destroy]`, fileItem._destroy || false);
    })
  }
  // END INPUT STAGE FORM


  // START OUTPUT STAGE FORM
  formData.append(`${ formName }[output_stage_form_attributes][id]`, node?.data?.output_stage_form?.id || '');

  if(node?.data?.output_stage_form?.field_items.length > 0){
    _.each(node.data.output_stage_form.field_items, (fieldItem, index) => {
      const fieldItemFormName = `${ formName }[output_stage_form_attributes][field_items_attributes]`;

      formData.append(`${ fieldItemFormName }[${ index }][id]`, fieldItem.id || '');
      formData.append(`${ fieldItemFormName }[${ index }][name]`, fieldItem.name || '');
      formData.append(`${ fieldItemFormName }[${ index }][data_type]`, fieldItem.data_type || '');
      formData.append(`${ fieldItemFormName }[${ index }][is_required]`, fieldItem.is_required || false);
      formData.append(`${ fieldItemFormName }[${ index }][group_name]`, fieldItem.group_name || '');
      formData.append(`${ fieldItemFormName }[${ index }][helping_text]`, fieldItem.helping_text || '');
      formData.append(`${ fieldItemFormName }[${ index }][_destroy]`, fieldItem._destroy || false);
    })
  }

  if(node?.data?.output_stage_form?.file_items.length > 0){
    _.each(node.data.output_stage_form.file_items, (fileItem, index) => {
      const fileItemFormName = `${ formName }[output_stage_form_attributes][file_items_attributes]`;

      formData.append(`${ fileItemFormName }[${ index }][id]`, fileItem.id || '');
      formData.append(`${ fileItemFormName }[${ index }][name]`, fileItem.name || '');
      formData.append(`${ fileItemFormName }[${ index }][is_required]`, fileItem.is_required || false);
      formData.append(`${ fileItemFormName }[${ index }][group_name]`, fileItem.group_name || '');
      formData.append(`${ fileItemFormName }[${ index }][helping_text]`, fileItem.helping_text || '');
      formData.append(`${ fileItemFormName }[${ index }][_destroy]`, fileItem._destroy || false);

      if(fileItem.template){
        formData.append(`${ fileItemFormName }[${ index }][template]`, fileItem.template || '');
      }
    })
  }
  // END OUTPUT STAGE FORM

  if(node?.data?.group?.id || node?.data?.group?.name){
    formData.append(`${ formName }[group_attributes][id]`, node?.data?.group?.isNew ? '' : (node?.data?.group?.id || ''));
    formData.append(`${ formName }[group_attributes][name]`, node?.data?.group?.name || "");

    _.each(node?.data?.group?.group_users, function(guser, index){
      if(guser.isNew){
        formData.append(`${ formName }[group_attributes][group_users_attributes][${ index }][id]`, '')
      } else {
        formData.append(`${ formName }[group_attributes][group_users_attributes][${ index }][id]`, guser.id || "")
      }

      formData.append(`${ formName }[group_attributes][group_users_attributes][${ index }][user_email]`, guser.email || "");
      formData.append(`${ formName }[group_attributes][group_users_attributes][${ index }][_destroy]`, guser._destroy || false);
    })
  }

  return formData;
}

const edgeFormData = (formData, formName, edge) => {
  formData.append(`${ formName }[id]`, edge?.data?.id || '');
  formData.append(`${ formName }[source_id]`, edge?.source);
  formData.append(`${ formName }[target_id]`, edge?.target);
  formData.append(`${ formName }[config]`, JSON.stringify(edge));

  const bridgeFieldItems = edge.data?.bridge_field_items;

  _.each(bridgeFieldItems, (bridgeFieldItem, index) => {
    formData.append(`${ formName }[bridge_field_items][][source_field_item_id]`, bridgeFieldItem.source_field_item_id || '')
    formData.append(`${ formName }[bridge_field_items][][target_field_item_id]`, bridgeFieldItem.target_field_item_id || '')
  })

  return formData;
}

const WorkflowBasicFlow = props => {
  const {
    getNode, getNodes, addNodes,
    getEdges,
    toObject,
    deleteElements
  } = useReactFlow();

  const [rfInstance, setRfInstance] = useState(null);
  const modalNodeFormRef = useRef(false);

  const {
    workflowProcess,
    currentCustomer,
    data,
    configuration: {
      process: {
        defaultRequestParams: processDefaultRequestParams,
        formName: processFormName
      },
      stage: {
        defaultRequestParams: stageDefaultRequestParams,
        formName: stageFormName
      },
      edge: {
        defaultRequestParams: edgeDefaultRequestParams,
        formName: edgeFormName
      },
      group: {
        defaultRequestParams: groupDefaultRequestParams
      },
      entity: entityConfiguration
    },
    routes: {
      requirementPublicPath
    },
    services: {
      stage: {
        createService: createStageService,
        updateService: updateStageService,
        destroyService: destroyStageService
      },
      edge: {
        createService: createEdgeService,
        updateService: updateEdgeService,
        destroyService: destroyEdgeService
      },
      group: {
        indexService: indexGroupService
      }
    }
  } = props;

  const initialNodes = _.map(workflowProcess.stages, 'config_react_flow');

  const initialEdges = _.map(workflowProcess.edges, 'config_react_flow');

  const [nodes, setNodes] = useNodesState(initialNodes);
  const [edges, setEdges] = useEdgesState(initialEdges);

  const [selectedNodeOnEdit, setSelectedNodeOnEdit] = useState(null);


  const getNodeId = () => {
    return `${ currentCustomer.hashid }-${ workflowProcess.hashid }-${ moment().toDate().getTime() }`
  }

  const isNodeInput = useCallback((node) => {
    return node.type == 'input'
  }, []);

  const isNodeOutput = useCallback((node) => {
    return node.type == 'output'
  }, []);

  const isNodeDefault = useCallback((node) => {
    return node.type == 'default'
  }, [])

  const isNodeShortlist = useCallback((node) => {
    return node.type == 'shortlist'
  })

  const isNodeDeletable = useCallback((node) => {
    return !(isNodeInput(node) || isNodeOutput(node))
  }, []);

  // Start Node Functions
  const onNodeUpdate = useCallback((node) => {
    const requestParams = { ... stageDefaultRequestParams, ... { id: node?.data?.id }}
    let formData = getStageFormData(node);

    updateStageService(requestParams, formData, response => {
      if(response.status == 200){
        setNodes((nds) => {
          return _.map(nds, nd => {
            if(nd.id == node.id){
              nd = response.data.config_react_flow
            }
            return nd
          })
        })
      } else {
        console.error('Error actualizar node')
        return node;
      }
    })
  }, [])

  const onNodeAdd = useCallback((e, type) => {
    const nodeId = getNodeId();

    const nodeIndex = getNodes().length + 1;

    const newNode = {
      ... defaultNodeOptions, ... {
        id: nodeId,
        data: {
          label: `Etapa ${ nodeIndex }`,
          description: `Descripción ${ nodeIndex }`
        },
        position: { x: 0, y: 0 },
        type: type,
      }
    }

    let formData = getStageFormData(newNode);

    createStageService(stageDefaultRequestParams, formData, response => {

      if(response.status == 201){
        addNodes(response.data.config_react_flow)
      } else {
        console.error('Error al crear stage');
      }
    })
  }, []);

  const onNodesDelete = useCallback((deletedNodes) => {
    let nodes = getNodes();

    const deletedNodesIds = _.map(deletedNodes, 'id');

    _.each(deletedNodes, node => {
      const requestParams = { ... stageDefaultRequestParams, ...{ id: node?.data?.id } };
      destroyStageService(requestParams, response => {
        if(response.status == 204){
        } else {
          console.error('Error al crear edge');
        }
      })
    })
  }, []);

  const onNodesChange = useCallback((changes) => {
    let customChanges = [];

    // Esto evita que el nodo se elimine
    // onNodesDelete El metodo cambia el hidden a true, para enviar el _destroy en true
    _.each(changes, change => {
      if(change.type != 'remove'){
        customChanges.push(change)
      }
    })

    setNodes((ns) => applyNodeChanges(changes, ns))
  }, [])

  const onNodeDragStart = useCallback((event, node ) => {
    localStorage.setItem('nodeDragStart', JSON.stringify(node))
  }, [])

  const onNodeDragStop = useCallback((event, node) => {
    const nodeStorage = JSON.parse(localStorage.getItem('nodeDragStart'));
    const positionChanged = (nodeStorage.position.x != node.position.x) || (nodeStorage.position.y != node.position.y)

    if(node.dragging && positionChanged){
      onNodeUpdate(node);
    }
  }, [])
  // End Node Functions

  // Start Edge Functions
  const onEdgeCreate = useCallback((edge) => {

    edge['id'] = `edge-${ edge.source }-${ edge.target }-${ moment().toDate().getTime() }`
    edge['type'] = 'directional'

    let formData = getEdgeFormData(edge);

    createEdgeService(edgeDefaultRequestParams, formData, response => {
      if(response.status == 201){
        setEdges((eds) => addEdge(response.data.config_react_flow, eds))
      } else{
        console.error('Error crear edge')
      }
    })
  }, [])

  const onEdgeUpdate = useCallback((edge) => {
    const requestParams = { ... edgeDefaultRequestParams, ... { id: edge?.data?.id }}
    let formData = getEdgeFormData(edge);

    updateEdgeService(requestParams, formData, response => {
      if(response.status == 200){
        setEdges((eds) => {
          return _.map(eds, ed => {
            if(ed.id == edge.id){
              ed = response.data.config_react_flow
            }
            return ed
          })
        })
      } else {
        console.error('Error actualizar edge')
        return node;
      }
    })
  }, [])

  const onEdgesDelete = useCallback((deletedEdges) => {
    let edges = getEdges()

    _.each(deletedEdges, edge => {
      const requestParams = { ... edgeDefaultRequestParams, ...{ id: edge?.data?.id } };
      destroyEdgeService(requestParams, response => {
        if(response.status == 204){
        } else {
          console.error('Error eliminar edge');
        }
      })
    })
  });

  const onEdgesChange = useCallback((changes) => {
    let customChanges = [];

    // Esto evita que el nodo se elimine
    // onEdgesDelete El metodo cambia el hidden a true, para enviar el _destroy en true
    // _.each(changes, change => {
    //   if(change.type != 'remove'){
    //     customChanges.push(change)
    //   }
    // })

    setEdges((eds) => applyEdgeChanges(changes, eds))
  }, [])
  // End Edge Functions

  const onConnect = useCallback((edge) => {
    onEdgeCreate(edge)
  }, [])

  // Start Helpers
  const getStageFormData = useCallback((node) => {
    let formData = new FormData();

    stageFormData(formData, stageFormName, node)
    return formData;
  }, [])

  const getEdgeFormData = useCallback((edge) => {
    let formData = new FormData();

    edgeFormData(formData, edgeFormName, edge)

    return formData;
  }, [])

  const selectNodeOnEdit = useCallback((node) => {
    modalNodeFormRef.current = true
    setSelectedNodeOnEdit(node)
  }, [])

  const unSelectNodeOnEdit = useCallback((node) => {
    modalNodeFormRef.current = false;
    setSelectedNodeOnEdit(null)
  }, [])

  // End Helpers

  const drawSidebar = () => {
    if(modalNodeFormRef.current){
      return(
        <WorkFlowNodeForm
          currentNode={ selectedNodeOnEdit }
        />
      )
    }
  }

  return (
    <WorkflowDiagramProvider value={{
      data: data,
      callbacks: {
        onNodeAdd: onNodeAdd,
        onNodeUpdate: onNodeUpdate,
        isNodeInput: isNodeInput,
        isNodeOutput: isNodeOutput,
        isNodeDefault: isNodeDefault,
        isNodeShortlist: isNodeShortlist,
        isNodeDeletable: isNodeDeletable,
        onEdgeUpdate: onEdgeUpdate,
        selectNodeOnEdit: selectNodeOnEdit,
        unSelectNodeOnEdit: unSelectNodeOnEdit
      },
      configuration: props?.configuration,
      refs: {
        modalNodeFormRef: modalNodeFormRef
      },
      helpers: {
        getStageFormData: getStageFormData,
        getEdgeFormData: getEdgeFormData
      },
      routes: {
        requirementPublicPath: requirementPublicPath
      },
      services: {
        stage: {
          updateService: updateStageService
        },
        group: {
          indexService: indexGroupService
        },
        edge: {
          updateService: updateEdgeService,
        }
      }
    }}>
      <div style={{ 'width': '100%', 'height': '100%', 'minHeight': '100vh' }}>
        { drawSidebar() }
        <ReactFlow
          nodes={ nodes }
          edges={ edges }
          onNodesChange={ onNodesChange }
          onEdgesChange={ onEdgesChange }
          onConnect={ onConnect }
          className="intersection-flow"
          minZoom={ 0.2 }
          maxZoom={ 4 }
          fitView
          selectNodesOnDrag={ false }
          nodeTypes={ nodeTypes }
          edgeTypes={ edgeTypes }
          defaultEdgeOptions={ defaultEdgeOptions }
          onInit={ setRfInstance }
          onNodesDelete={ onNodesDelete }
          onEdgesDelete={ onEdgesDelete }
          onNodeDragStop={ onNodeDragStop }
          onNodeDragStart={ onNodeDragStart }
        >
          <WorkflowToolbar/>
          <Background />
          <Controls />
          <MiniMap/>
        </ReactFlow>
      </div>
    </WorkflowDiagramProvider>
  );
}

export default WorkflowBasicFlow;
