import React, {
  useMemo,
  useEffect,
  useRef,
  useCallback
} from 'react';

import I18n from "i18n-js";

import {
  FormGroup,
  Label,
  Input,
  Button,
  FormText
} from 'reactstrap';

import Select from 'react-select'

import FieldError from '../../../../helper/FieldError';
import SelectEmailNotifications from '../../../../abstract/bulk/request/document_config/select_email_notifications'
import DocumentPreview from '../../../../document/preview'
import BulkRequestDocumentConfigPreviewTemplate from './preview_template';
import DocumentSignerConfigAttributes from '../../../../document/signer_config_attributes';
import SignerFormList from '../../../../signer/form_list';

import {
  inputId,
  inputName,
  drawTooltipHelp,
  tooltipInitializaer
} from '../../../../helper/form';

import {
  onChangeDocumentHelper
} from '../../../../document/helper';

import DocumentSignerConfigContext from '../../../../document/context/signer_config_context'

const BulkRequestDocumentConfigForm = React.memo((props) => {
  const {
    bulkRequestConfig,
    callbackOnChangeConfig,
    index,
    data: {
      documentTypes,
      templates,
      customFieldEmailNotifications,
      allowedFormatFiles,
      entitiesSelected,
      shortlistProjects
    },
    configuration: {
      context,
      shortlist: shortlistConfiguration
    },
    formName
  } = props;

  const signersObjectRef = useRef({signers: bulkRequestConfig?.signers || []})

  useEffect(() => {
    signersObjectRef.current = { signers: bulkRequestConfig.signers || []}
  }, [bulkRequestConfig?.signers])

  const documentSignerConfigContextValue = useMemo(() => {
    return {
      resource: {
        require_signers: bulkRequestConfig?.require_signers,
        require_signers_order: bulkRequestConfig?.require_signers_order,
        require_fao: bulkRequestConfig?.require_fao,
        isPersisted: false
      },
    }
  }, [
    bulkRequestConfig?.require_signers,
    bulkRequestConfig?.require_signers_order,
    bulkRequestConfig?.require_fao
  ])

  useEffect(() => {
    tooltipInitializaer()
  }, [bulkRequestConfig])

  const shortlistProjectById = useCallback((id) => {
    return _.find(shortlistProjects, { id: id } )
  })

  const handleConfigData = (event, key) => {
    let value = event.target.value;
    const type = event.target.type;

    let _bulkRequestConfig = { ... bulkRequestConfig };

    if(type == 'checkbox'){
      value = event.target.checked;
    }

    if(type == 'file'){
      value = event.target.files[0]
    }

    _bulkRequestConfig = {
      ... bulkRequestConfig,
      ... onChangeDocumentHelper(_bulkRequestConfig, key, value, documentTypes)
    }

    _bulkRequestConfig[key] = value;

    if(key == 'shortlist_project_id'){
      _bulkRequestConfig['shortlist_project_name'] = shortlistProjectById(value)?.title;
    }


    if(_.isFunction(callbackOnChangeConfig)){
      callbackOnChangeConfig(_bulkRequestConfig, index)
    }
  }

  // ---------- START DOCUMENT TYPE ----------
  const documentTypeSelectInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ bulkRequestConfig?.errors?.document_type_id || [] }>
          <Label
            for={ inputId(formName, 'document_type_id') }
            className="required"
          >
            { I18n.t('activerecord.attributes.document.document_type_id') }
          </Label>

          <Select
            id={ inputId(formName, 'document_type_id') }
            name={ inputName(formName, 'document_type_id') }
            invalid={ _.has(bulkRequestConfig?.errors, 'document_type_id') }
            value={ documentTypeSelectedOption }
            onChange={ e => handleConfigData({ target: { value: e?.value }}, "document_type_id") }
            options={ documentTypeSelectOptions }
            isClearable={ true }
            placeholder={ `-- Selecciona un ${ I18n.t('activerecord.attributes.document.document_type_id') } --` }
          />
        </FieldError>
      </FormGroup>
    )
  }

  const documentTypeSelectedOption = useMemo(() => {
    let selected = _.find(documentTypes, { id: bulkRequestConfig?.document_type_id } )

    if(selected){
      return { value: selected.id, label: selected.name }
    } else {
      return null
    }
  }, [bulkRequestConfig?.document_type_id])

  const documentTypeSelectOptions = useMemo(() => {
    return _.map(documentTypes, function(document_type){
      return { value: document_type.id, label: document_type.name }
    })
  }, [])
  // ---------- END DOCUMENT TYPE ----------

  // ---------- START LABEL ----------
  const labelInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ bulkRequestConfig?.label?.errors || [] }>
          <Label
            for={ inputId(formName, 'label') }
          >
            { I18n.t('activerecord.attributes.document.label') }
          </Label>

          { drawTooltipHelp(I18n.t('documents.form.label_tooltip')) }

          <Input
            type='text'
            id={ inputId(formName, 'label') }
            name={ inputName(formName, 'label') }
            invalid={ _.has(bulkRequestConfig?.errors, 'label') }
            value={ bulkRequestConfig?.label || '' }
            onChange={ e => handleConfigData(e, "label") }
          />
        </FieldError>
      </FormGroup>
    )
  }
  // ---------- END LABEL ----------

  // ---------- START UPLOADED BY ----------

  const uploadByEmployee = () => {
    if(context == 'laboral_bulk'){
      return(
        <div className="form-check">
          <Input
            type='radio'
            className='form-check-input'
            name={ inputName(formName, 'uploaded_by') }
            id={ inputId(formName, 'uploaded_by_upload_file_employee') }
            value={ 'upload_file_employee' }
            checked={ bulkRequestConfig?.uploaded_by == 'upload_file_employee' }
            onChange={ e => handleConfigData(e, 'uploaded_by') }
            invalid={ _.has(bulkRequestConfig?.errors, 'uploaded_by') }
          />
          <Label
            className='form-check-label d-block'
            for={ inputId(formName, 'uploaded_by_upload_file_employee') }
          >
            <i className="fas fa-file-import fa-lg mr-2 text-muted"></i>
            { I18n.t('activerecord.attributes.laboral/bulk/request/hiring_document_config.uploaded_by.upload_file_employee') }
          </Label>
        </div>
      )
    }
  }

  const uploadByAbstractRepresentative = () => {
    if(context == 'abstract_bulk'){
      return(
        <div className="form-check">
          <Input
            type='radio'
            className='form-check-input'
            name={ inputName(formName, 'uploaded_by') }
            id={ inputId(formName, 'uploaded_by_upload_file_employee') }
            value={ 'upload_file_employee' }
            checked={ bulkRequestConfig?.uploaded_by == 'upload_file_employee' }
            onChange={ e => handleConfigData(e, 'uploaded_by') }
            invalid={ _.has(bulkRequestConfig?.errors, 'uploaded_by') }
          />
          <Label
            className='form-check-label'
            for={ inputId(formName, 'uploaded_by_upload_file_employee') }
          >
            <i className="fas fa-file-import fa-lg mr-2 text-muted"></i>
            { I18n.t('activerecord.attributes.abstract/bulk/request/document_config.uploaded_by.upload_file_employee') }
          </Label>

          { function(){
            if(context == 'abstract_bulk' && bulkRequestConfig.uploaded_by == 'upload_file_employee'){
              return(
                <div className="mt-3">
                  { emailNotificationSelect() }
                </div>
              )
            }
          }()}
        </div>
      )
    }
  }

  const uploadByClient = () => {
    if(context == 'corporate_bulk'){
      return(
        <div className="form-check">
          <Input
            type='radio'
            className='form-check-input'
            name={ inputName(formName, 'uploaded_by') }
            id={ inputId(formName, 'uploaded_by_upload_file_client') }
            value={ 'upload_file_client' }
            checked={ bulkRequestConfig?.uploaded_by == 'upload_file_client' }
            onChange={ e => handleConfigData(e, 'uploaded_by') }
            invalid={ _.has(bulkRequestConfig?.errors, 'uploaded_by') }
          />
          <Label
            className='form-check-label'
            for={ inputId(formName, 'uploaded_by_upload_file_client') }
          >
            <i className="fas fa-file-upload fa-lg mr-2 text-muted"></i>
            { I18n.t('activerecord.attributes.corporate/bulk/request/agreement_document_config.uploaded_by.upload_file_client') }
          </Label>
        </div>
      )
    }
  }

  const uploadByProvider = () => {
    if(context == 'corporate_bulk'){
      return(
        <div className="form-check">
          <Input
            type='radio'
            className='form-check-input'
            name={ inputName(formName, 'uploaded_by') }
            id={ inputId(formName, 'uploaded_by_upload_file_provider') }
            value={ 'upload_file_provider' }
            checked={ bulkRequestConfig?.uploaded_by == 'upload_file_provider' }
            onChange={ e => handleConfigData(e, 'uploaded_by') }
            invalid={ _.has(bulkRequestConfig?.errors, 'uploaded_by') }
          />
          <Label
            className='form-check-label'
            for={ inputId(formName, 'uploaded_by_upload_file_provider') }
          >
            <i className="fas fa-file-upload fa-lg mr-2 text-muted"></i>
            { I18n.t('activerecord.attributes.corporate/bulk/request/agreement_document_config.uploaded_by.upload_file_provider') }
          </Label>
        </div>
      )
    }
  }

  const uploadedByInput = () => {
    return(
      <FormGroup>
        <div className="fw-bold mb-2">
          { I18n.t(`activerecord.attributes.laboral/bulk/request/hiring_document_config.file`) }
        </div>
        <FieldError errors={ bulkRequestConfig?.uploaded_by?.errors || [] }>
          <div className="card mb-1">
            <div className="card-header options-card bg-real-light">
              <div className="form-check">
                <Input
                  type='radio'
                  className='form-check-input'
                  name={ inputName(formName, 'uploaded_by') }
                  id={ inputId(formName, 'uploaded_by_upload_file_user') }
                  value={ 'upload_file_user' }
                  checked={ bulkRequestConfig?.uploaded_by == 'upload_file_user' }
                  onChange={ e => handleConfigData(e, 'uploaded_by') }
                  invalid={ _.has(bulkRequestConfig?.errors, 'uploaded_by') }
                />
                <Label
                  className='form-check-label d-block'
                  for={ inputId(formName, 'uploaded_by_upload_file_user') }
                >
                  <i className="fas fa-upload fa-lg mr-2 text-muted"></i>
                  { I18n.t('activerecord.attributes.laboral/bulk/request/hiring_document_config.uploaded_by.upload_file_user') }
                </Label>
              </div>
            </div>
            {bulkRequestConfig.uploaded_by == 'upload_file_user' &&
              <React.Fragment>
                <div className="card-body">
                  { fileInput() }
                  { keepFormatSwitchInput() }
                </div>
              </React.Fragment>
            }
          </div>
          <div className="card mb-1">
            <div className="card-header options-card bg-real-light">
              { uploadByEmployee() }
              { uploadByAbstractRepresentative() }
              { uploadByClient() }
              { uploadByProvider() }
            </div>
            {bulkRequestConfig.uploaded_by == 'upload_file_employee' &&
              <React.Fragment>
                <div className="card-body">
                  { requireUploadDeadlineSwitchInput() }
                  { uploadDeadlineAtInput() }
                  { keepFormatSwitchInput() }
                </div>
              </React.Fragment>
            }
            { function(){
              if(bulkRequestConfig.uploaded_by == 'upload_file_provider' || bulkRequestConfig.uploaded_by == 'upload_file_client'){
                return(
                  <React.Fragment>
                    <div className="card-body">
                      { keepFormatSwitchInput() }
                    </div>
                  </React.Fragment>
                )
              }
            }()}
          </div>
          <div className="card mb-1">
            <div className="card-header options-card bg-real-light">
              <div className="form-check">
                <Input
                  type='radio'
                  className='form-check-input'
                  name={ inputName(formName, 'uploaded_by') }
                  id={ inputId(formName, 'template') }
                  value={ 'controldoc_form_template' }
                  checked={ bulkRequestConfig?.uploaded_by == 'controldoc_form_template' }
                  onChange={ e => handleConfigData(e, 'uploaded_by') }
                  invalid={ _.has(bulkRequestConfig?.errors, 'uploaded_by') }
                />
                <Label
                  className='form-check-label d-block'
                  for={ inputId(formName, 'template') }
                >
                  <i className="fas fa-paste fa-lg mr-2 text-muted"></i>
                  { I18n.t('activerecord.attributes.laboral/bulk/request/hiring_document_config.uploaded_by.upload_controldoc_form') }

                </Label>
              </div>
            </div>
            {bulkRequestConfig?.uploaded_by == 'controldoc_form_template' &&
              <React.Fragment>
                <div className="card-body">
                  { autocompletedByEntityInput() }
                  { controldocFormTemplateSelectInput() }
                  { previewTemplate() }
                </div>
              </React.Fragment>
            }
          </div>

          { uploadedByShortlistProject() }
        </FieldError>
      </FormGroup>
    )
  }
  // ---------- END UPLOADED BY ----------


  // ---------- START SHORTLIST PROJECT ----------
  const enabledShorlistProject = useMemo(() => {
    return shortlistConfiguration?.enabled && context == 'laboral_bulk'
  }, [shortlistConfiguration, context])

  const uploadedByShortlistProject = () => {
    if(enabledShorlistProject){
      return(
        <div className="card mb-1">
          <div className="card-header options-card bg-real-light">
            <div className="form-check">
              <Input
                type='radio'
                className='form-check-input'
                name={ inputName(formName, 'uploaded_by') }
                id={ inputId(formName, 'shortlist_project') }
                value={ 'shortlist_project' }
                checked={ bulkRequestConfig?.uploaded_by == 'shortlist_project' }
                onChange={ e => handleConfigData(e, 'uploaded_by') }
                invalid={ _.has(bulkRequestConfig?.errors, 'uploaded_by') }
              />
              <Label
                className='form-check-label d-block'
                for={ inputId(formName, 'shortlist_project') }
              >
                <i className="far fa-comment-alt fa-lg mr-2 text-muted"></i>
                { I18n.t('activerecord.attributes.laboral/bulk/request/hiring_document_config.uploaded_by.upload_shortlist_project') }

              </Label>
            </div>
          </div>
          {bulkRequestConfig?.uploaded_by == 'shortlist_project' &&
            <React.Fragment>
              <div className="card-body">
                { shortlistProjectSelectInput() }
              </div>
            </React.Fragment>
          }
        </div>
      )
    }
  }

  const shortlistProjectSelectOptions = useMemo(() => {
    const _shortlistProjects = _.sortBy(shortlistProjects, 'title')

    return _.map(_shortlistProjects, shortlistProject => {
      return { value: shortlistProject.id, label: shortlistProject.title }
    })
  }, [shortlistProjects])

  const shortlistProjectSelectedOption = useMemo(() => {
    const options = shortlistProjectSelectOptions;

    return _.find(options, { value: bulkRequestConfig?.shortlist_project_id}) || null

  }, [bulkRequestConfig?.shortlist_project_id])

  const shortlistProjectSelectInput = () => {
    return(
      <FormGroup>
        <FieldError errors={ bulkRequestConfig?.errors?.shortlist_project_id || []  }>
          <Label
            for={ inputId(formName, 'shortlist_project_id') }
            className="required"
          >
            { I18n.t('activerecord.attributes.laboral/bulk/request/hiring_document_config.shortlist_project_id') }
          </Label>

          <Select
            id={ inputId(formName, 'shortlist_project_id') }
            name={ inputName(formName, 'shortlist_project_id') }
            options={ shortlistProjectSelectOptions }
            value={ shortlistProjectSelectedOption }
            onChange={ e => handleConfigData({ target: { value: e?.value }}, "shortlist_project_id") }
            isClearable={ true }
            placeholder={ '-- Selecciona un proyecto de Shortlist --' }
            invalid={ _.has(bulkRequestConfig?.errors, 'shortlist_project_id') }
          />
        </FieldError>
      </FormGroup>
    )
  }

  // ---------- START SHORTLIST PROJECT ----------

  // ---------- START AUTOCOMPLETED BY ENTITY ----------
  const autocompletedByEntityInput = () => {
    if(bulkRequestConfig?.uploaded_by == 'controldoc_form_template') {
      return(
        <>
          <FormGroup className='ml-3'>
            <FieldError errors={ bulkRequestConfig?.autocompleted_by_entity?.errors || [] }>
              <div className="form-check">
                <Input
                  type='radio'
                  className='form-check-input'
                  name={ inputName(formName, 'autocompleted_by_entity') }
                  id={ inputId(formName, 'autocompleted_by_entity_false') }
                  value={ "0" }
                  checked={ bulkRequestConfig?.autocompleted_by_entity == false }
                  onChange={ e => handleConfigData(e, 'autocompleted_by_entity') }
                />
                <Label
                  className='form-check-label'
                  for={ inputId(formName, 'autocompleted_by_entity_false') }
                >
                  { I18n.t('activerecord.attributes.laboral/bulk/request/hiring_document_config.autocompleted_by_entity_false') }
                </Label>
              </div>

              <div className="form-check">
                <Input
                  type='radio'
                  className='form-check-input'
                  name={ inputName(formName, 'autocompleted_by_entity') }
                  id={ inputId(formName, 'autocompleted_by_entity_true') }
                  value={ "1" }
                  checked={ bulkRequestConfig?.autocompleted_by_entity == true }
                  onChange={ e => handleConfigData(e, 'autocompleted_by_entity') }
                  invalid={ _.has(bulkRequestConfig?.errors, 'autocompleted_by_entity') }
                />
                <Label
                  className='form-check-label'
                  for={ inputId(formName, 'autocompleted_by_entity_true') }
                >
                  { I18n.t('activerecord.attributes.laboral/bulk/request/hiring_document_config.autocompleted_by_entity_true') }
                </Label>
              </div>
            </FieldError>
          </FormGroup>
          { function(){
            if(context == 'abstract_bulk' && bulkRequestConfig.uploaded_by == 'controldoc_form_template'){
              return(
                <div className="mt-3">
                  { emailNotificationSelect() }
                </div>
              )
            } else if(context == 'corporate_bulk' && bulkRequestConfig.uploaded_by == 'controldoc_form_template'){
              return(
                <div className="mt-3">
                  { requestedToSelectInput() }
                </div>
              )
            }
          }()}

        </>
      )
    }
  }
  // ---------- END AUTOCOMPLETED BY ENTITY ----------

  // ---------- START TEMPLATE ----------
  const controldocFormTemplateSelectInput = () => {
    if(bulkRequestConfig.uploaded_by == 'controldoc_form_template') {
      return (
        <FormGroup>
          <FieldError errors={ bulkRequestConfig?.errors?.template || []  }>
            <Label
              for={ inputId(formName, 'template_id') }
              className="required"
            >
              { I18n.t('activerecord.attributes.laboral/bulk/request/hiring_document_config.templates') }
            </Label>

            <Select
              id={ inputId(formName, 'template_id') }
              name={ inputName(formName, 'template_id') }
              value={ templateSelectedOption }
              onChange={ e => handleConfigData({ target: { value: e?.value }}, "template_id") }
              options={ templateSelectOptions }
              isClearable={ true }
              placeholder={ '-- Selecciona una plantilla --' }
              invalid={ _.has(bulkRequestConfig?.errors, 'template') }
            />
          </FieldError>
        </FormGroup>
      )
    }
  }

  const templateSelectedOption = useMemo(() => {
    const selected = _.find(templates, { id: bulkRequestConfig?.template_id });

    if(selected){
      return { label: selected?.name, value: selected?.id }
    } else {
      return null
    }
  }, [bulkRequestConfig?.template_id])

  const templateSelectOptions = useMemo(() => {
    return _.map(templates, template => ({value: template.id, label: template.name}))
  }, [templates])
  // ---------- END TEMPLATE ----------

  // ---------- START REQUESTED_TO (OnlyCorporate) ----------
  const requestedToSelectInput = () => {
    if(context == 'corporate_bulk'){
      return(
        <div className="row">
          <div className="col-xs-12">
            <FormGroup>
              <Input
                type='select'
                value={ bulkRequestConfig?.requested_to || "" }
                onChange={ e => handleConfigData(e, 'requested_to') }
                invalid={ _.has(bulkRequestConfig?.errors, 'requested_to') }
              >
                <option
                  value={ "" }
                  key={ _.uniqueId(`request-to-option-`) }
                >
                  { "Selecciona un representante" }
                </option>
                <option
                  value={ "legal_representative_client" }
                  key={ "request-to-option-client" }
                >
                  { I18n.t('activerecord.attributes.corporate/bulk/request/agreement_document_config.requested_to.legal_representative_client') }
                </option>
                <option
                  value={ "legal_representative_provider" }
                  key={ "request-to-option-provider" }
                >
                  { I18n.t('activerecord.attributes.corporate/bulk/request/agreement_document_config.requested_to.legal_representative_provider') }
                </option>
              </Input>
            </FormGroup>
          </div>
        </div>
      )
    }
  }

  // ---------- END REQUESTED_TO (OnlyCorporate)----------

  // ---------- START SELECT EMAIL NOTIFICATION (OnlyAbstract) ----------
  const emailNotificationSelect = () =>{
    if(context == 'abstract_bulk'){
      return(
        <SelectEmailNotifications
          value={ bulkRequestConfig.custom_field_id }
          handleConfigData={ handleConfigData }
          customFieldEmailNotifications={ customFieldEmailNotifications }
          errors={ bulkRequestConfig.errors || {} }
        />
      )
    }
  }
  // ---------- END SELECT EMAIL NOTIFICATION (OnlyAbstract) ----------

  const previewTemplate = () => {
    if(bulkRequestConfig.uploaded_by == 'controldoc_form_template' && bulkRequestConfig.template_id && entitiesSelected.length > 0){
      return (
        <BulkRequestDocumentConfigPreviewTemplate
          entitiesSelected={ entitiesSelected }
          data={ props?.data }
          bulkRequestConfig={ bulkRequestConfig }
        />
      )
    }
  }


  // ---------- START FILE ----------
  const fileInput = () => {
    if(bulkRequestConfig.uploaded_by == 'upload_file_user'){
      return(
        <FormGroup>
          <FieldError errors={ bulkRequestConfig?.errors?.file || [] }>
            <Label
              for={ inputId(formName, 'file') }
              className="required"
            >
              { I18n.t(`activerecord.attributes.abstract/bulk/request/document_config.file`) }
            </Label>

            <Input
              type='file'
              id={ inputId(formName, `file`) }
              name={ inputName(formName, 'file') }
              onChange={ e => handleConfigData(e, 'file') }
              invalid={ _.has(bulkRequestConfig?.errors, 'file') }
              accept={ _.map(allowedFormatFiles, 'type')}
            />

            <DocumentPreview
              file={ bulkRequestConfig?.file }
            />
          </FieldError>
        </FormGroup>
      )
    }
  }
  // ---------- END FILE ----------

  // ---------- START KEEP FORMAT ----------
  const keepFormatSwitchInput = () => {
    if(bulkRequestConfig?.uploaded_by !== 'controldoc_form_template'){
      return(
        <FormGroup>
          <FieldError errors={ bulkRequestConfig?.errors?.keep_format || [] }>
            <div className='custom-switch'>
              <Input
                className='custom-control-input'
                type='checkbox'
                name={ inputName(formName, 'keep_format') }
                id={ inputId(formName, 'keep_format') }
                invalid={ _.has(bulkRequestConfig?.errors, 'keep_format') }
                onChange={ e => handleConfigData(e, 'keep_format') }
                checked={ bulkRequestConfig?.keep_format || false }
                disabled={ bulkRequestConfig?.require_signers }
              />
              <Label
                className='custom-control-label'
                for={ inputId(formName, 'keep_format') }
              >
                { I18n.t(`activerecord.attributes.document.keep_format`) }
                { drawTooltipHelp(I18n.t('documents.new.keep_format_help')) }
              </Label>
              {bulkRequestConfig.require_signers &&
                <FormText color="info" className="mt-0 lh-sm">
                  <i className="fas fa-info mr-2"></i>
                  Solo disponible en documentos sin firmantes
                </FormText>
              }
            </div>
          </FieldError>
        </FormGroup>
      )
    }
  }
  // ---------- END KEEP FORMAT ----------

  // ---------- START REQUIREUPLOADDEADLINE ----------
  const requireUploadDeadlineSwitchInput = () => {
    if(bulkRequestConfig?.uploaded_by == 'upload_file_employee'){
      return(
        <FormGroup>
            <FieldError errors={ bulkRequestConfig?.errors?.require_upload_deadline || [] }>
            <div className='custom-switch'>
              <Input
                className='custom-control-input'
                type='checkbox'
                name={ inputName(formName, 'require_upload_deadline') }
                id={ inputId(formName, 'require_upload_deadline') }
                invalid={ _.has(bulkRequestConfig?.errors, 'require_upload_deadline') }
                onChange={ e => handleConfigData(e, 'require_upload_deadline') }
                checked={ bulkRequestConfig?.require_upload_deadline || false }
              />
              <Label
                className='custom-control-label'
                for={ inputId(formName, 'require_upload_deadline') }
              >
                { I18n.t(`activerecord.attributes.abstract/bulk/request/document_config.require_upload_deadline`) }
                { drawTooltipHelp(I18n.t('abstract.bulk.request.document_configs.require_upload_deadline_help')) }
              </Label>
            </div>
          </FieldError>
        </FormGroup>
      )
    }
  }

  const uploadDeadlineAtInput = () => {
    if(bulkRequestConfig?.require_upload_deadline){
      return(
        <FormGroup>
          <FieldError errors={ bulkRequestConfig?.errors?.upload_deadline_at }>
            <Input
              type='datetime-local'
              id={ inputId(formName, 'upload_deadline_at') }
              name={ inputName(formName, 'upload_deadline_at') }
              invalid={ _.has(bulkRequestConfig?.errors, 'upload_deadline_at') }
              onChange={ e => handleConfigData(e, 'upload_deadline_at') }
              value={ bulkRequestConfig?.upload_deadline_at ? moment(bulkRequestConfig.upload_deadline_at).format('YYYY-MM-DD HH:mm') : '' }
            />
          </FieldError>
        </FormGroup>
      )
    }
  }
  // ---------- START REQUIREUPLOADDEADLINE ----------


  // ---------- START SIGNERS ----------
  const signerFormList = () => {
    if(bulkRequestConfig.require_signers){
      return(
        <div className="card card-outline card-primary">
          <div className="card-header">
            { I18n.t('activerecord.models.signer.one') }
          </div>
          <div className="card-body">
            <SignerFormList
              signers={ bulkRequestConfig?.signers || [] }
              formName={ `${ formName }[signer_attributes]` }
              setResource={ onHandleSigner }
            />
          </div>
        </div>
      )
    }
  }

  const onHandleSigner = (_function) => {
    const object = _function(signersObjectRef.current)

    signersObjectRef.current = object;

    if(_.isFunction(callbackOnChangeConfig)){
      callbackOnChangeConfig(
        { signers: signersObjectRef.current.signers } ,
        index
      )
    }
  }
  // ---------- END SIGNERS ----------

  // ---------- START DELETE BUTTON ----------
  const deleteButton = () => {
    return(
      <FormGroup className='text-end'>
        <Button
          color='danger'
          size='sm'
          onClick={ e => onConfirmDestroyConfig() }
          outline
        >
          <i className="fas fa-file-excel mr-1"></i> { I18n.t('laboral.bulk.request.hiring_documents.form.remove_config') }
        </Button>
      </FormGroup>
    )
  }

  const onConfirmDestroyConfig = () => {
    swalWithBootstrap.fire({
      title: 'Eliminar solicitud de documento',
      html: '¿Estás seguro de eliminar el documento?',
    }).then( result => {
      if(result.isConfirmed){
        onDestroyConfig()
      }
    })
  }

  const onDestroyConfig = () => {
    bulkRequestConfig._destroy = true

    if(_.isFunction(callbackOnChangeConfig)){
      callbackOnChangeConfig(bulkRequestConfig, index);
    }
  }

  // ---------- END DELETE BUTTON ----------
  return(
    <DocumentSignerConfigContext.Provider value={ documentSignerConfigContextValue }>
      <div className={ `row ${ bulkRequestConfig._destroy ? 'd-none' : '' }` }>
        <div className="col-12">
          <div className="card accordion-item overflow-hidden mb-2">
            <div className="accordion-header fw-bold" id={`headingToggler-${index}`}>
              <button
                className="accordion-button collapsed text-muted text-center"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collapseToggler-${index}`}
                aria-expanded="true"
                aria-controls={`collapseToggler-${index}`}
              >
                <div className="row w-100">
                  <div className="col-md-12 text-left fw-bold">
                    <i className="fas fa-file-alt mr-2"></i>
                    { I18n.t('activerecord.models.document.one') }{" "}
                    {bulkRequestConfig.label}
                  </div>
                </div>
              </button>
            </div>
            <div id={`collapseToggler-${index}`}
              className="card-body accordion-body accordion-collapse collapse show"
              aria-labelledby={`headingToggler-${index}`}
              data-bs-parent="#accordionDocs">
              { documentTypeSelectInput() }
              { labelInput() }

              { uploadedByInput() }

              <div className="fw-bold mb-2">
                <hr/>
                { I18n.t('activerecord.models.signer.other') }
              </div>

              <DocumentSignerConfigAttributes
                handleResource={ handleConfigData }
                resource={ bulkRequestConfig }
                formName={ formName }
              />

              { signerFormList() }

              { deleteButton() }
            </div>
          </div>
        </div>
      </div>
    </DocumentSignerConfigContext.Provider>
  )
})

export default BulkRequestDocumentConfigForm;
