import React, {
  useState,
  useMemo,
  useCallback
} from 'react';

import {
  FormGroup,
  Button,
  Form
} from 'reactstrap';

import I18n from 'i18n-js';

import WorkflowRequirementStageAttributes from '../requirement_stage/attributes';

const WorkflowRequirementForm = props => {

  const {
    configuration: {
      formName = "workflow_requirement",
      defaultRequestParams
    },
    routes: {
      indexRequirementsPath,
      showRequirementPath
    },
    services: {
      createRequirement: createRequirementService
    }
  } = props;

  const [requirement, setRequirement] = useState(props?.requirement || {})

  const onChangeRequirement = (event, key) => {
    let value = event.target.value;

    setRequirement(prevState => {
      return { ... prevState, [key]: value }
    })
  }

  const onChangeRequirementStage = (event, key) => {
    const value = event.target.value;

    let currentRequirementStage = {
      ... requirement.current_requirement_stage,
      [key]: value
    }

    onChangeRequirement(
      { target: { value: currentRequirementStage }},
      'current_requirement_stage'
    )
  }

  const onConfirmSubmit = (event) => {
    event.preventDefault();
    swalWithBootstrap.fire({
      title: I18n.t('workflow.requirements.form.confirm.title'),
      html: I18n.t('workflow.requirements.form.confirm.message'),
    }).then( result => {
      if(result.isConfirmed){
        onSubmit();
      }
    })
  }

  const onSuccessSubmit = useCallback((_requirement) => {
    let requestParams = {
      ...defaultRequestParams,
      id: _requirement.hashid,
      _options: true,
      format: ''
    }

    if(_requirement.token){
      requestParams = { ... requestParams, token: _requirement.token }
    }

    window.location = showRequirementPath(requestParams)
  }, [])

  const onSubmit = () => {
    const formData = requirementFormData();

    createRequirementService(defaultRequestParams, formData, response => {
      if(response.status == 201){
        onSuccessSubmit(response.data)
      } else {
        setRequirement(response.data);
      }
    })
  }

  const requirementFormData = () => {
    let _formData = new FormData();

    _formData.append(`${ formName }[process_id]`, requirement.process_id || '');


    // CurrentRequirementStage
    const currentRequirementStageFormName = `${ formName }[current_requirement_stage_attributes]`
    const currentRequirementStage = requirement.current_requirement_stage;

    _formData.append(`${ currentRequirementStageFormName }[id]`, currentRequirementStage.id || '');
    _formData.append(`${ currentRequirementStageFormName }[current_stage_id]`, currentRequirementStage.current_stage_id || '');
    _formData.append(`${ currentRequirementStageFormName }[observations]`, currentRequirementStage.observations || '');

    if(currentRequirementStage.node_type == 'input'){
      _formData.append(`${ currentRequirementStageFormName }[aasm_state_event]`, '');
    } else {
      _formData.append(`${ currentRequirementStageFormName }[aasm_state_event]`, currentRequirementStage.aasm_state_event || '');
    }


    _formData.append(`${ currentRequirementStageFormName }[output_form_attributes][id]`, currentRequirementStage.output_form.id || '')

    // START OUTPUT FIELD ITEMS
    const outputFieldItemFormName = `${ currentRequirementStageFormName }[output_form_attributes][field_items_attributes]`;
    const outputFieldItems = currentRequirementStage.output_form.field_items || [];
    _.each(outputFieldItems, (fieldItem, index) => {
      _formData.append(`${ outputFieldItemFormName }[${ index }][id]`, fieldItem?.id || '');

      _formData.append(`${ outputFieldItemFormName }[${ index }][item_id]`, fieldItem.item_id || '');
      _formData.append(`${ outputFieldItemFormName }[${ index }][data_type]`, fieldItem.data_type || 'text');
      _formData.append(`${ outputFieldItemFormName }[${ index }][is_required]`, fieldItem.is_required || false );
      _formData.append(`${ outputFieldItemFormName }[${ index }][name]`, fieldItem.name || '' );

      _formData.append(`${ outputFieldItemFormName }[${ index }][value]`, fieldItem?.value || '');
    });
    // END OUTPUT FIELD ITEMS

    // START OUTPUT FILE ITEMS
    const outputFileItemFormName = `${ currentRequirementStageFormName }[output_form_attributes][file_items_attributes]`;
    const outputFileItems = currentRequirementStage.output_form.file_items || [];
    _.each(outputFileItems, (fileItem, index) => {
      _formData.append(`${ outputFileItemFormName }[${ index }][id]`, fileItem?.id || '');

      _formData.append(`${ outputFileItemFormName }[${ index }][value]`, fileItem?.value || '');

      _formData.append(`${ outputFileItemFormName }[${ index }][data_type]`, fileItem.data_type || 'text');
      _formData.append(`${ outputFileItemFormName }[${ index }][is_required]`, fileItem.is_required || false );
      _formData.append(`${ outputFileItemFormName }[${ index }][name]`, fileItem.name || '' );

      if(fileItem.file){
        _formData.append(`${ outputFileItemFormName }[${ index }][file]`, fileItem?.file || '');
      }
    })
    // END OUTPUT FILE ITEMS

    return _formData;
  }

  const backButton = useMemo(() => {
    if(!requirement.is_public){
      return(
        <a
          href={ indexRequirementsPath(defaultRequestParams) }
          className='btn btn-default'
        >
          { I18n.t('actions.back') }
        </a>
      )
    }
  })

  return(
    <Form onSubmit={ event => onConfirmSubmit(event) }>
      <div className="row">
        <div className="col-12">
          <WorkflowRequirementStageAttributes
            requirementStage={ requirement.current_requirement_stage }
            configuration={{
              formName: formName
            }}
            callbacks={{
              onChangeRequirementStage: onChangeRequirementStage
            }}
          />
        </div>
        <div className="col-12">
          <FormGroup className='text-end'>
            { backButton }

            <Button
              color='success'
              className='ml-2'
              type='submit'
            >
              { I18n.t('actions.send') }
            </Button>
          </FormGroup>
        </div>
      </div>
    </Form>
  )
}

export default WorkflowRequirementForm
