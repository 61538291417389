import React, {
  useState,
  useMemo,
  useContext,
  useCallback
} from 'react';

import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getBezierPath,
  useReactFlow,
  MarkerType
} from 'reactflow';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';

import I18n from 'i18n-js';

import Select from 'react-select';

import WorkflowDiagramContext from '../../diagram_context';

const WorkflowCustomEdge = ({ edge, config }) => {

  const {
    getEdge,
    getNode,
    deleteElements
  } = useReactFlow();

  const {
    callbacks: {
      onEdgeUpdate
    }
  } = useContext(WorkflowDiagramContext);

  const [openBridgeInformationModal, setOpenBridgeInformationModal] = useState(false)

  const {
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    markerEnd,
    markerStart,
    style={}
  } = edge;

  const { type } = config;

  const isDirectionalEdge = useMemo(() => {
    return type == 'directional';
  }, [edge])

  const isBidirectionalEdge = useMemo(() => {
    return type == 'bidirectional';
  }, [edge])

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const toggleToDirectional = useCallback(() => {
    let edge = getEdge(id);

    edge.type = 'directional';

    onEdgeUpdate(edge);
  }, [edge])

  const toggleToBidirectional = useCallback(() => {
    let edge = getEdge(id);
    edge.type = 'bidirectional';

    onEdgeUpdate(edge);
  }, [edge])

  const toggleEdge = () => {
    let edge = getEdge(id);
    if(isDirectionalEdge){
      toggleToBidirectional()
    } else {
      toggleToDirectional()
    }
  }

  const onDeleteEdge = () => {
    const edge = getEdge(id);

    deleteElements({edges: [edge]});
  }

  const toolbar = () => {
    return(
      <div
        style={{
          position: 'absolute',
          transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
          fontSize: 12,
          pointerEvents: 'all',
        }}
        className="nodrag nopan"
      >
        { buttonDirectionalEdge() }
        { buttonBridgeInformation() }
        { buttonDeleteEdge() }
      </div>
    )
  }

  const buttonDirectionalEdge = () => {
    const sourceNode = getNode(edge.source);
    const targetNode = getNode(edge.target)

    if(sourceNode.type == 'default' && targetNode.type == 'default'){
      return (
        <button
          className="btn btn-xs btn-light edgebutton mx-1"
          onClick={ e => toggleEdge() }
        >
          { isBidirectionalEdge ?
            <i className="fas fa-long-arrow-alt-right"></i> :
            <i className="fas fa-arrows-alt-h"></i>
          }
        </button>
      )
    }
  }

  const buttonDeleteEdge = () => {
    return (
      <Button
          size='xs'
          color='danger'
          className='mx-1'
          onClick={ e => onDeleteEdge() }
        >
          <i className="fas fa-trash-alt"></i>
      </Button>
    )
  }

  const buttonBridgeInformation = () => {
    return(
      <Button
        size='xs'
        color='primary'
        className='mx-1'
        onClick={ e => setOpenBridgeInformationModal(true) }
      >
        <i className="fas fa-plug"></i>
      </Button>
    )
  }

  const modalBridgeInformation = () => {
    return(
      <WorkflowEdgeBridgeFieldItemInformation
        openBridgeInformationModal={ openBridgeInformationModal }
        setOpenBridgeInformationModal={ setOpenBridgeInformationModal }
        edge={ getEdge(edge.id) }
      />
    )
  }

  return (
    <>
      { modalBridgeInformation() }
      <BaseEdge
        path={ edgePath }
        markerEnd={ markerEnd }
        markerStart={ isBidirectionalEdge ? markerStart : '' }
        style={ style }

      />
      <EdgeLabelRenderer>
        { toolbar() }
      </EdgeLabelRenderer>
    </>
  );
}

export default React.memo(WorkflowCustomEdge);

const WorkflowEdgeBridgeFieldItemInformation = props => {

  const {
    getNode,
    getEdge,
    setEdges
  } = useReactFlow();

  const {
    openBridgeInformationModal,
    setOpenBridgeInformationModal
  } = props;

  const [edge, setEdge] = useState(props.edge);

  const sourceNode = getNode(edge.source);
  const targetNode = getNode(edge.target)

  const {
    data: {
      input_stage_form: {
        field_items: targetInputFieldItems
      }
    }
  } = targetNode;

  const {
    data: {
      output_stage_form: {
        field_items: sourceOutputFieldItems
      }
    }
  } = sourceNode;

  const {
    configuration: {
      edge: {
        defaultRequestParams: edgeDefaultRequestParams
      }
    },
    helpers: {
      getEdgeFormData
    },
    services: {
      edge: {
        updateService: updateEdgeService
      }
    }
  } = useContext(WorkflowDiagramContext);

  const onChangeBridgeInformationFieldItem = (event, targetFieldItemId) => {
    const sourceFieldItemId = event.target.value;

    let bridgeFieldItems = [ ... edge.data.bridge_field_items ];

    const index = _.findIndex(bridgeFieldItems, fieldItem => {
      return String(fieldItem.target_field_item_id) == String(targetFieldItemId)
    })

    if(index >= 0){
      bridgeFieldItems[index].source_field_item_id = sourceFieldItemId;
    } else {
      bridgeFieldItems.push({
        target_field_item_id: targetFieldItemId,
        source_field_item_id: sourceFieldItemId
      })
    }

    setEdge(prevState => {
      return {
        ... prevState,
        data: {
          ...prevState.data,
          bridge_field_items: bridgeFieldItems
        }
      }
    })
  }

  // ---------- START SOURCE OUTPUT FIELD ITEM ----------
  const sourceOutputFieldItemsSelect = (targetFieldItemId) => {
    return(
      <>
        <Select
          options={ sourceOutputFieldItemsSelectOptions }
          isSearchable={ true }
          isClearable={ true }
          value={ sourceOutputFieldItemsSelectedOption(targetFieldItemId) }
          onChange={ e => onChangeBridgeInformationFieldItem({ target: { value: e?.value || '' }}, targetFieldItemId) }
        />
      </>
    )
  }

  const sourceOutputFieldItemsSelectedOption = targetFieldItemId => {

    const fieldItems = edge.data.bridge_field_items || []

    const selected = _.find(fieldItems, fieldItem => {
      return String(fieldItem.target_field_item_id) == String(targetFieldItemId)
    })?.source_field_item_id

    if(selected){
      const sourceOutputFieldItem = _.find(sourceOutputFieldItems, outputFieldItem => { return String(outputFieldItem.id) == String(selected) })

      return { value: sourceOutputFieldItem.id, label: sourceOutputFieldItem.name }
    } else {
      return null
    }
  }

  const sourceOutputFieldItemsSelectOptions = useMemo(() => {
    return _.map(sourceOutputFieldItems, outputFieldItem => { return { label: outputFieldItem.name, value: outputFieldItem.id }} )
  }, [sourceOutputFieldItems])
  // ---------- END SOURCE OUTPUT FIELD ITEM ----------

  const modalOnKeyDown = event => {
    if(event.key == 'Enter'){
      onSubmitEdge()
    }
  }

  const onSubmitEdge = () => {
    const requestParams = { ... edgeDefaultRequestParams, ... { id: edge?.data?.id }}

    const formData = getEdgeFormData(edge);

    updateEdgeService(requestParams, formData, response => {
      if(response.status == 200){
        setEdges((eds) => {
          return _.map(eds, ed => {
            if(ed.id == edge.id){
              ed = response.data.config_react_flow
            }
            return ed
          })
        })

        swalToastBootstrap.fire({
          icon: 'success',
          title: I18n.t('workflow.edges.update.successfully')
        });

        setOpenBridgeInformationModal(false)
      } else {
        swalToastBootstrap.fire({
          icon: 'error',
          title: I18n.t('errors.unprocessable_entity')
        });
      }
    })
  }

  return (
    <Modal
      isOpen={ openBridgeInformationModal }
      toggle={ event => setOpenBridgeInformationModal(false) }
      unmountOnClose={ true }
      size='lg'
      onKeyDown={ event => modalOnKeyDown(event) }
    >
      <ModalHeader
        toggle={ event => setOpenBridgeInformationModal(false) }
      >
        Información entre etapas
      </ModalHeader>
      <ModalBody>
        <div className="row">
          <div className="col-12">
            <table className="table">
              <thead>
                <tr>
                  <th>{ targetNode?.data?.label }</th>
                  <th>{ sourceNode?.data?.label }</th>
                </tr>
              </thead>
              <tbody>
                { _.map(targetInputFieldItems, (targetFieldItem, index) => {
                  return(
                    <tr key={ `trFieldItem-${ targetFieldItem.id }` }>
                      <td>{ targetFieldItem.name }</td>
                      <td>
                        { sourceOutputFieldItemsSelect(targetFieldItem.id) }
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>

          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="default"
          onClick={ event => setOpenBridgeInformationModal(false) }
        >
          { I18n.t('actions.close') }
        </Button>
        <Button
          color="success"
          onClick={ event => onSubmitEdge() }
        >
          { I18n.t('actions.save') }
        </Button>
      </ModalFooter>
    </Modal>
  )
}
