import React, {
  useMemo
} from 'react';

import {
  FormGroup,
  Label,
  Input
} from 'reactstrap';

import I18n from 'i18n-js';

import FieldError from '../../helper/FieldError';

import {
  inputId,
  inputName,
  drawTooltipHelp
} from '../../helper/form';

import {
  fileItemIsRequired
} from '../requirement_stage/helper';

const WorkflowRequirementStageFormItemFileAttributes = props => {
  const {
    requirementStage,
    fileItem,
    configuration: {
      formName, index, defaultRequestParams
    },
    callbacks: {
      onChangeFileItem: callbackOnChangeFileItem
    }
  } = props;

  const showShortlistPath = props?.actions?.showShortlistPath || ""

  const previewPdfUrlShortlist = useMemo(() => {
    let hashid = requirementStage?.shortlist_assessment_load?.hashid

    return hashid && _.isFunction(showShortlistPath) ? showShortlistPath({...{...defaultRequestParams, id: hashid, format: 'pdf', _options: true }}) : false

  }, [requirementStage?.shortlist_assessment_load])

  const isRequired = useMemo(() => {
    return fileItemIsRequired(fileItem)
  }, [fileItem.is_required])

  const onChangeFileItem = (event) => {
    const value = event.target.files[0];

    let _fileItem = { ... fileItem };
    _fileItem['file'] = value;


    callbackOnChangeFileItem(_fileItem, _fileItem.item.id)
  }

  const helpingText = () => {
    if(fileItem.helping_text){
      return(
        <span className='text-muted small'>{ fileItem.helping_text }</span>
      )
    }
  }

  const inputFile = () => {
    if(!!previewPdfUrlShortlist){
      return (
        <iframe
          width="100%"
          height="500"
          src={ previewPdfUrlShortlist || '' }
          name={ fileItem.name }
          title={ fileItem.name }
          type="application/pdf"
        >
        </iframe>
      )
    } else {
      return(
        <FormGroup>
          <FieldError errors={ _.get(fileItem, 'errors.file') || [] } >
            <Label
              className={ isRequired ? 'required' : ''}
              htmlFor={ inputId(formName, 'value') }
            >
              { fileItem.name }
            </Label>
            <Input
              type={ 'file' }
              required={ isRequired }
              onChange={ e => onChangeFileItem(e) }
              invalid={ _.has(fileItem.errors, 'file') }
              id={ inputId(formName, 'value') }
              name={ inputName(formName, 'value') }
            />
            { helpingText() }
            { templateAttachment }
          </FieldError>
        </FormGroup>
      )
    }
  }

  const templateAttachment = useMemo(() => {
    if(fileItem.blob_url){
      console.log(fileItem);

      return(
        <div>
          <span className='text-muted small'>
            Plantilla de documento:&nbsp;
            <a
              href={ fileItem.blob_url }
              download={ true }
            >
              { I18n.t('actions.download') }
            </a>
          </span>
        </div>
      )
    }
  }, [fileItem.attachment])

  return(
    <div className="row">
      <div className="col-12">
        <FormGroup>
          { inputFile() }
        </FormGroup>
      </div>
    </div>
  )
}

export default React.memo(WorkflowRequirementStageFormItemFileAttributes);
